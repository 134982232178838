<script>
  import { isDoneOrPending } from '../lib/utils'

  export let campaign
</script>

{#if campaign && !campaign.contractAddress}
  {#if campaign.deploying?.status === 'pending'}
    <div class="tag is-light is-info ml-2 is-rounded">Deploying</div>
  {:else}
    <div class="tag is-light is-warning ml-2 is-rounded">Draft</div>
  {/if}
{/if}
{#if campaign && campaign.network !== 'mainnet'}
  <div class="tag is-light is-danger ml-2 is-rounded">Testnet</div>
{/if}
{#if campaign && campaign.batches.length && campaign.batches.every(batch => isDoneOrPending(batch.burning))}
  <div class="tag is-black ml-2 is-rounded">{!campaign.batches.some(batch => batch.burning?.status === 'pending') ? 'Burned' : 'Burning'}</div>
{/if}
{#if campaign?.archived}
  <div class="tag is-light ml-2 is-rounded">Archived</div>
{/if}
