<script>
  import { Button, Icon, Input, Field, Notification, Dialog } from 'svelma-fixed'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Loader from '../components/Loader.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import { router, RouterLink } from '../router'
  import ActionExecutor from '../components/ActionExecutor.svelte'
  import { ensureNetwork, sendTx, activeWalletAddress } from '../stores/onboard'
  import pluralize from 'pluralize'
  import { getOpenSeaUrl } from '../lib/utils'
  import FeesWidget from '../components/FeesWidget.svelte'

  export let campaignId
  export let batchId

  const loading = createLoadingStore()
  let loadingPromise

  let campaign
  let batch

  let remainingAudienceCount
  let numberOfTokensToMint

  async function load () {
    await (loadingPromise = loading(async () => {
      campaign = await apiCall('GET', uri`/api/campaigns/${campaignId}`)
      batch = campaign.batches.find(b => b.id === batchId)
      if (!batch) throw new Error('Batch not found in campaign')

      if (campaign.batches.some(b => b.id !== batchId && ['scheduled', 'processing', 'pending'].includes(b.minting?.status)) && !(batch.minting?.task || batch.minting?.hash)) {
        Dialog.alert({
          title: 'Minting in progress',
          message: 'There is another batch currently being minted. Please wait until it is finished before minting this batch.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'exclamation-triangle',
          confirmText: 'OK'
        })

        $router.push({ name: 'batchDetails', params: { campaignId, batchId } })
      }

      const { count } = await apiCall('GET', uri`/api/campaigns/${campaignId}/remainingAudienceCount`)
      numberOfTokensToMint = remainingAudienceCount = count
    }))
  }

  load()

  const deploying = createLoadingStore()

  let scheduledTask

  async function deploy (setHash) {
    await deploying(async () => {
      const { txDetails } = await apiCall('POST', uri`/api/campaigns/${campaignId}/deploy`)

      await ensureNetwork(campaign.network)
      const hash = await sendTx({ ...txDetails })
      await setHash(hash)
    })
  }

  const minting = createLoadingStore()
  let mintingGasOptions

  async function mint (setHash) {
    await minting(async () => {
      const { txDetails, batch: { start, end } } = await apiCall('POST', uri`/api/campaigns/${campaignId}/batches/${batchId}/mint`, { count: numberOfTokensToMint, sender: $activeWalletAddress, scheduledTask, gasOptions: mintingGasOptions })
      batch.start = start
      batch.end = end

      if (!scheduledTask) {
        await ensureNetwork(campaign.network)
        const hash = await sendTx({ ...txDetails })
        await setHash(hash)
      } else {
        await setHash(null)
      }
    })
  }
</script>

<svelte:head>
	<title>Mint Batch - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      Mint Batch {batch?.metadata?.name ? `"${batch.metadata.name}"` : ''}
    </h4>

    {#await loadingPromise}
      <Loader borderless />
    {:then}
      {#if !campaign.contractAddress}
        <ActionExecutor path={uri`/api/campaigns/${campaignId}/deployment`} network={campaign.network} text="Deploying contract..." let:setHash on:success={load}>
          <div class="has-text-centered">
            <h1 class="title is-1 my-5">Deploy campaign contract</h1>
            <p class="my-5"><i class="big-icon fa fa-scroll"></i></p>
            <p class="is-size-4">In order to mint the first batch of NFTs in this campaign, the campaign's contract has to be deployed to the blockchain first.</p>
            <FeesWidget --max-width="700px" {campaignId} operation="deploy" />
            <p class="my-5"><Button size="is-large" type="is-primary" on:click={() => deploy(setHash)} loading={$deploying}>Deploy</Button></p>
            <p><RouterLink to={{ name: 'batchDetails', params: { campaignId, batchId } }}><Icon icon="arrow-left" /> Back</RouterLink></p>
          </div>

          <div slot="pending">
            This operation will continue in the background.
            <RouterLink to={{ name: 'batchDetails', params: { campaignId, batchId } }}><Icon icon="arrow-left" /> Back</RouterLink>
          </div>
        </ActionExecutor>
      {:else}
        <ActionExecutor path={uri`/api/campaigns/${campaignId}/batches/${batchId}/minting`} network={campaign.network} text="Minting batch..." let:setHash>
          {#if remainingAudienceCount <= 0}
            <ErrorDisplay type="is-warning" title="No audience members left!">
              <p>There are no audience members left to mint NFTs for. Please add more audience members to the campaign.</p>
              <Button type="is-warning" outlined inverted on:click={() => $router.push({ name: 'campaignAudience', params: { id: campaignId } })}>Manage Audience</Button>
              <Button type="is-warning" outlined inverted on:click={() => $router.push({ name: 'batchDetails', params: { campaignId, batchId } })}>Back</Button>
            </ErrorDisplay>
          {:else}
            <form on:submit|preventDefault={() => mint(setHash)}>
              <fieldset disabled={$minting}>
                <Notification showClose={false}><Icon icon="info-circle" /> Your <RouterLink to={{ name: 'campaignAudience', params: { id: campaignId } }}>audience</RouterLink> has <strong>{pluralize('member', remainingAudienceCount, true)}</strong> left.</Notification>

                <Field label="Number of Tokens to Mint" message="The recipients will be automatically selected from fresh members of your audience.">
                  <Input type="number" max={remainingAudienceCount} min={1} bind:value={numberOfTokensToMint} required />
                </Field>

                <FeesWidget {campaignId} operation="mint" numberOfTokens={Number(numberOfTokensToMint) ?? 0} bind:gasOptions={mintingGasOptions} bind:scheduledTask allowScheduledTask />

                <div class="field is-grouped">
                  <div class="control">
                    <Button type="is-primary" nativeType="submit" loading={$minting}>{scheduledTask ? 'Schedule Minting of Tokens' : 'Mint Tokens'}</Button>
                  </div>
                  <div class="control">
                    <Button on:click={() => $router.push({ name: 'batchDetails', params: { campaignId, batchId } })} disabled={$minting}>Cancel</Button>
                  </div>
                </div>
              </fieldset>
            </form>
          {/if}

          <div slot="pending">
            This operation will continue in the background.
            <RouterLink to={{ name: 'batchDetails', params: { campaignId, batchId } }}><Icon icon="arrow-left" /> Back</RouterLink>
          </div>

          <div slot="success" class="has-text-centered">
            <h1 class="title is-1 my-5">Batch Minted!</h1>
            <p class="my-5"><i class="big-icon fa fa-check-circle"></i></p>
            <p class="is-size-4">The batch has been successfully minted. Check out the <a href={getOpenSeaUrl(campaign.contractAddress, batch.start, campaign.network)} target="_blank" rel="noreferrer">first token on OpenSea</a>!</p>
            <p class="my-5"><Button size="is-large" on:click={() => $router.push({ name: 'batchDetails', params: { campaignId, batchId } })}>Done</Button></p>
          </div>
        </ActionExecutor>
      {/if}
    {:catch error}
      <ErrorDisplay title="Failed to load batch" {error} />
    {/await}
  </div>
</div>
