<script>
  import ModalDialog from '../ModalDialog.svelte'
  import { Button, Input } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  let reason = ''
</script>

<ModalDialog on:close title="Unsubscribe" closeable>
  <p>
    Please tell us why this NFT drop collection isn't for you:
  </p>
  <Input bind:value={reason} autofocus />

  <svelte:fragment slot="footer">
    <Button type="is-primary" on:click={dispatch('close', reason)}>Submit</Button>
    <Button on:click={() => dispatch('close')}>Cancel</Button>
  </svelte:fragment>
</ModalDialog>
