<script>
  import { onMount, onDestroy, createEventDispatcher } from 'svelte'

  export let interval = 10000
  export let tickCounter = 0
  export let always = false
  export let skipFirstTick = false

  const dispatch = createEventDispatcher()

  let appliedInterval
  let intervalId
  let mounted = false

  function clear () {
    if (intervalId) {
      clearInterval(intervalId)
      intervalId = null
    }
  }

  function doTick () {
    tickCounter++
    tickCounter >>= 0 // truncate to 32 bits so that we can never get into the area where ++ becomes a no-op
    dispatch('tick', tickCounter)
  }

  function setup () {
    clear()

    if (interval) {
      intervalId = setInterval(() => doTick(), interval)
    }

    if (!skipFirstTick) doTick()
  }

  $: if (mounted && interval !== appliedInterval) {
    appliedInterval = interval

    setup()
  }

  onMount(() => {
    mounted = true // Also triggers setup
  })

  onDestroy(() => {
    clear()

    mounted = false
  })
</script>

{#key always ? tickCounter : null}
  <slot {tickCounter} />
{/key}
