<script>
  import DetailsTable from './DetailsTable.svelte'
  import { Input, Toast } from 'svelma-fixed'
  import { escapeRegexp, formatCurrency, getAddressShortLabel } from '../lib/utils'
  import { router } from '../router'

  export let search = ''
  export let tasks = []
  export let balances = {}

  $: searchRegexp = new RegExp(`\\b${escapeRegexp(search.trim())}`, 'i')

  let type = 'all'

  const filterTasks = tasks => tasks.filter(task => (
    (!search || task.campaignName?.match(searchRegexp) || task.batchName?.match(searchRegexp) || task.actionTxHash?.match(searchRegexp)) &&
    (type === 'all' || (type === 'pending' && ['scheduled', 'processing', 'pending'].includes(task.status)) || type === task.status)
  ))

  function onTaskClick (task) {
    if (['scheduled', 'processing', 'pending', 'success'].includes(task.status)) {
      $router.push({ name: 'mintBatch', params: { campaignId: task.campaign, batchId: task.batch } })
    } else {
      Toast.create({ message: 'This task can no longer be viewed.', type: 'is-warning' })
    }
  }
</script>

<DetailsTable>
  <div class="level">
    <div class="level-left">
      <div class="tabs is-toggle is-toggle-rounded">
        <ul>
          <li class:is-active={type === 'all'}><a href={undefined} on:click={() => (type = 'all')}>All <div class="tag is-dark is-rounded ml-2">{tasks.length}</div></a></li>
          <li class:is-active={type === 'pending'}><a href={undefined} on:click={() => (type = 'pending')}>Pending <div class="tag is-dark is-rounded ml-2">{tasks.filter(task => ['scheduled', 'processing', 'pending'].includes(task.status)).length}</div></a></li>
          <li class:is-active={type === 'success'}><a href={undefined} on:click={() => (type = 'success')}>Done <div class="tag is-dark is-rounded ml-2">{tasks.filter(task => task.status === 'success').length}</div></a></li>
          <li class:is-active={type === 'failed'}><a href={undefined} on:click={() => (type = 'failed')}>Failed <div class="tag is-dark is-rounded ml-2">{tasks.filter(task => task.status === 'failed').length}</div></a></li>
          <li class:is-active={type === 'cancelled'}><a href={undefined} on:click={() => (type = 'cancelled')}>Cancelled <div class="tag is-dark is-rounded ml-2">{tasks.filter(task => task.status === 'cancelled').length}</div></a></li>
        </ul>
      </div>
    </div>
    <div class="level-right">
      <Input bind:value={search} placeholder="Search task" type="search" expanded />
    </div>
  </div>

  {#each (type, filterTasks(tasks)) as task (task.id)}
    <div class="level hoverable" on:click={() => onTaskClick(task)} on:keypress>
      <div class="level-left">
        <div class="level-item is-justify-content-flex-start">
          <figure class="image is-64x64">
            <img style:height="100%" style:object-fit="contain" src={task.batchImage ?? '/images/nft_placeholder.png'} alt="task media" on:error={e => (e.target.src = '/images/nft_placeholder.png')} />
          </figure>
          <div class="ml-2">
            {task.description ?? 'Unknown task'}
            {#if task.network !== 'mainnet'}
              <div class="tag is-light is-danger ml-2 is-rounded">Testnet</div>
            {/if}
            {#if task.status === 'scheduled'}
              <div class="tag is-info ml-2 is-rounded">Scheduled</div>
            {:else if task.status === 'processing'}
              <div class="tag is-info ml-2 is-rounded">Processing</div>
            {:else if task.status === 'pending'}
              <div class="tag is-info ml-2 is-rounded">Pending</div>
            {:else if task.status === 'success'}
              <div class="tag is-success ml-2 is-rounded">Done</div>
            {:else if task.status === 'failed'}
              <div class="tag is-danger ml-2 is-rounded">Failed</div>
            {:else if task.status === 'cancelled'}
              <div class="tag is-light ml-2 is-rounded">Cancelled</div>
            {/if}
            {#if task.suspended}
              <div class="tag is-light ml-2 is-rounded">Suspended</div>
            {/if}
            <br />
            <small>
              {#if task.executedAt}
                Executed at {new Date(task.updatedAt).toLocaleString()}
              {:else}
                Valid till {new Date(task.validTill).toLocaleString()}
              {/if}
            </small>
            {#if task.reason}
              <br />
              <small class="has-text-danger">{task.reason}</small>
            {/if}
          </div>
          <br />
        </div>
      </div>
      <div class="level-right has-text-right-tablet">
        <div class="level-item is-justify-content-flex-start">
          <div>
            {#if task.actionTxHash}
              {task.withdrawnEth?.toFixed(6)} {balances[task.network]?.tokenName ?? '???'} ({formatCurrency(task.withdrawnEth * task.ethPriceOnExecution ?? 0, 'USD', 2, '-', true)})
            {:else}
              {task.maxCostEth?.toFixed(6)} {balances[task.network]?.tokenName ?? '???'} ({formatCurrency(task.maxCostEth * balances[task.network]?.ethPrice ?? 0, 'USD', 2, '-', true)})
            {/if}
            <br />
            <small>
              {#if task.actionTxHash}
                {getAddressShortLabel(task.actionTxHash)}
              {:else}
                Not executed
              {/if}
            </small>
          </div>
        </div>
      </div>
    </div>
  {:else}
    {#if search}
      <div>There are no tasks matching "{search}".</div>
    {:else}
      <div>No tasks found.</div>
    {/if}
  {/each}
</DetailsTable>
