<script>
  import { Field, Input, Button, Dialog } from 'svelma-fixed'
  import { onboard, getProvider } from '../stores/onboard'
  import { createLoadingStore } from '../stores/loading'
  import { authenticate, updateUserInfo, userInfo } from '../stores/auth'
  import html from 'html-template-tag'

  const connectLoading = createLoadingStore()

  let wallets

  async function connect () {
    await connectLoading(async () => {
      wallets = await onboard.connectWallet()
      console.log(wallets)
    })
  }

  const signLoading = createLoadingStore()

  let message = 'Hello, World!'
  let signature = ''

  async function sign () {
    await signLoading(async () => {
      const provider = getProvider()
      const signer = provider.getSigner()
      signature = await signer.signMessage(message)
      console.log(signature)
    })
  }

  const authLoading = createLoadingStore()

  async function auth () {
    await authLoading(async () => {
      const { userInfo } = await authenticate()
      console.log(userInfo)
      Dialog.alert({
        title: 'Authentication successful',
        message: html`User info: ${JSON.stringify(userInfo, null, 2)}`,
        type: 'is-success'
      })
    })
  }
</script>

<svelte:head>
	<title>TX Test 2 - Tailor</title>
</svelte:head>

<style lang="scss">
</style>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      TX Test Page 2
    </h4>
  </div>

  <div class="box my-4">
    Wallets:
    <pre>{JSON.stringify(wallets, (key, value) => key === 'provider' ? '<provider>' : value, 2)}</pre>
    <hr />
    <Button on:click={connect} loading={$connectLoading}>Connect</Button>
  </div>

  <div class="box my-4">
    Sign message:
    <Field>
      <Input type="textarea" bind:value={message}></Input>
    </Field>
    <Button on:click={sign} loading={$signLoading}>Sign</Button>
    <hr />
    <pre>{signature}</pre>
  </div>

  <div class="box my-4">
    Authenticate:
    <hr />
    <Button on:click={auth} loading={$authLoading}>Authenticate</Button>
  </div>

  <div class="box my-4">
    User info:
    <pre>{JSON.stringify($userInfo, null, 2)}</pre>
    <hr />
    <Button on:click={() => updateUserInfo()}>Update</Button>
  </div>
</div>
