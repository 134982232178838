<script>
  import { Button, Dialog, Field, Icon, Input, Select, Toast } from 'svelma-fixed'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Loader from '../components/Loader.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import { router } from '@spaceavocado/svelte-router'
  import OptionCard from '../components/OptionCard.svelte'
  import { formatError, generateRandomString } from '../lib/utils'
  import CampaignTags from '../components/CampaignTags.svelte'
  import { toChecksumAddress } from 'ethereum-checksum-address'
  import Debouncer from '../lib/Debouncer'
  import pluralize from 'pluralize'

  export let id
  export let continueToMint = false

  const loading = createLoadingStore()
  let loadingPromise

  let campaign

  async function load () {
    await (loadingPromise = loading(async () => {
      campaign = await apiCall('GET', uri`/api/campaigns/${id}`, { withFullAudience: true })
      if (!campaign.audience.activityTimeframe) campaign.audience.activityTimeframe = window.appVariables.activityTimeframeDays[0]
      loadSegmentationPreview()
    }))
  }

  load()

  const saving = createLoadingStore()

  async function save () {
    try {
      campaign.audience.addresses = [...new Set((campaign.audience.addresses ?? []).map(a => toChecksumAddress(a)))]
    } catch (e) {
      console.error(e)
      return Dialog.alert({
        message: formatError(e),
        type: 'is-danger'
      })
    }

    await saving(async () => {
      campaign = await apiCall('POST', uri`/api/campaigns/${id}/updateAudience`, {
        audience: campaign.audience
      })
    })

    Toast.create({
      message: 'Audience updated.',
      type: 'is-success'
    })

    $router.push({ name: (continueToMint && !campaign.batches.length) ? 'newBatch' : 'campaignDetails', params: { id } })
  }

  const groupName = generateRandomString(6)

  function updateAddresses (e) {
    campaign.audience.addresses = e.target.value.split(/\n/).map(x => x.trim()).filter(x => x)
  }

  const sources = {
    'trading.binance': false,
    'trading.binanceUs': false,
    'trading.robinhood': false,
    'trading.metamask': false,
    'trading.opensea': false,
    'trading.secondaryMarketplaces': false
  }

  function updateUi () {
    if (!campaign.audience.sources) campaign.audience.sources = []
    sources['trading.binance'] = campaign.audience.sources.includes('trading.binance')
    sources['trading.binanceUs'] = campaign.audience.sources.includes('trading.binanceUs')
    sources['trading.robinhood'] = campaign.audience.sources.includes('trading.robinhood')
    sources['trading.metamask'] = campaign.audience.sources.includes('trading.metamask')
    sources['trading.opensea'] = campaign.audience.sources.includes('trading.opensea')
    sources['trading.secondaryMarketplaces'] = campaign.audience.sources.includes('trading.secondaryMarketplaces')
  }

  const segmentationPreviewDebouncer = new Debouncer()
  let segmentationPreviewPromise = Promise.resolve(0)

  async function loadSegmentationPreview () {
    segmentationPreviewPromise = (async () => {
      const { addressesCount } = await apiCall('POST', uri`/api/segmentationPreview`, { audience: campaign.audience })
      return addressesCount
    })()
  }

  $: if (campaign) {
    updateUi()

    if (campaign.audience.type === 'segmented') {
      segmentationPreviewDebouncer.debounce(loadSegmentationPreview)
    }
  }

  function updateSources () {
    campaign.audience.sources = Object.entries(sources).filter(([k, v]) => v).map(([k, v]) => k)
  }
</script>

<svelte:head>
	<title>Audience for {campaign?.name ?? 'Campaign'} - Tailor</title>
</svelte:head>

<style lang="scss">
  .checkbox-line {
    display: block;
    margin-bottom: 0.5rem;
  }
</style>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      Audience {campaign?.name ? `for "${campaign.name}"` : ''}
      <CampaignTags campaign={campaign} />
    </h4>
    {#await loadingPromise}
      <Loader borderless />
    {:then}
      <form on:submit|preventDefault={save}>
        <fieldset disabled={$saving}>
          <div class="type-group mb-5">
            <OptionCard name={groupName} value="list" bind:group={campaign.audience.type} disabled={$saving}>
              <div slot="title">Simple list</div>
              <div slot="subtitle">
                Provide a list of addresses to target.
              </div>
              <Field label="Addresses">
                <Input type="textarea" rows={10} value={(campaign.audience.addresses ?? []).map(x => x + '\n').join('')} on:change={updateAddresses} />
              </Field>
            </OptionCard>
            {#if window.appVariables.disableSegmentation && campaign.audience.type !== 'segmented'}
              <OptionCard name={groupName} value="segmented" bind:group={campaign.audience.type} disabled>
                <div slot="title">Activity segmentation <div class="tag tag-grey">COMING SOON</div></div>
              </OptionCard>
            {:else}
              <OptionCard name={groupName} value="segmented" bind:group={campaign.audience.type}>
                <div slot="title">Activity segmentation <div class="tag tag-grey">BETA</div></div>
                <div slot="subtitle">
                  Use our chain-analysis technology to automatically target your audience out of millions of active addresses based on their blockchain activity.
                </div>

                <div class="columns">
                  <div class="column is-half">
                    <Field label="Audience Profile">
                      <div>
                        <label class="checkbox checkbox-line">
                          <input type="checkbox" bind:checked={sources['trading.binance']} on:change={updateSources}>
                          Centralized crypto traders Non-US
                        </label>
                        <label class="checkbox checkbox-line">
                          <input type="checkbox" bind:checked={sources['trading.binanceUs']} on:change={updateSources}>
                          Centralized crypto traders US
                        </label>
                        <label class="checkbox checkbox-line">
                          <input type="checkbox" bind:checked={sources['trading.robinhood']} on:change={updateSources}>
                          Centralized crypto/stock traders US
                        </label>
                        <label class="checkbox checkbox-line">
                          <input type="checkbox" bind:checked={sources['trading.metamask']} on:change={updateSources}>
                          Decentralized in-wallet instant exchange users
                        </label>
                        <label class="checkbox checkbox-line">
                          <input type="checkbox" bind:checked={sources['trading.opensea']} on:change={updateSources}>
                          NFT traders using OpenSea
                        </label>
                        <label class="checkbox checkbox-line">
                          <input type="checkbox" bind:checked={sources['trading.secondaryMarketplaces']} on:change={updateSources}>
                          NFT traders using other marketplaces
                        </label>
                      </div>
                    </Field>
                  </div>
                  <div class="column is-half">
                    <Field label="Activity Timeframe">
                      <Select bind:selected={campaign.audience.activityTimeframe} expanded disabled={window.appVariables.activityTimeframeDays.length === 1}>
                        {#each window.appVariables.activityTimeframeDays as days}
                          <option value={days}>{pluralize('day', days, true)}</option>
                        {/each}
                      </Select>
                    </Field>
                    <Field label="Extra Filters">
                      <div>
                        <label class="checkbox checkbox-line">
                          <input type="checkbox" bind:checked={campaign.audience.isInterestingUser}>
                          Only users with high chance of noticing Tailor NFTs
                        </label>
                      </div>
                    </Field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column is-half">
                    <Field label="Minimum number of NFTs owned">
                      <Input type="number" bind:value={campaign.audience.minNfts} />
                    </Field>
                  </div>
                  <div class="column is-half">
                    <Field label="Maximum number of NFTs owned">
                      <Input type="number" bind:value={campaign.audience.maxNfts} />
                    </Field>
                  </div>
                </div>

                <!-- FIXME: Temporarily disabled because we don't have any working way to get seniority
                  <div class="columns">
                    <div class="column is-half">
                      <Field label="Minimum wallet seniority">
                        <div class="field-body">
                          <div class="field has-addons">
                            <Input type="number" bind:value={campaign.audience.minSeniority} expanded />
                            <p class="control">
                              <Button type="is-static">days</Button>
                            </p>
                          </div>
                        </div>
                      </Field>
                    </div>
                    <div class="column is-half">
                      <Field label="Maximum wallet seniority">
                        <div class="field-body">
                          <div class="field has-addons">
                            <Input type="number" bind:value={campaign.audience.maxSeniority} expanded />
                            <p class="control">
                              <Button type="is-static">days</Button>
                            </p>
                          </div>
                        </div>
                      </Field>
                    </div>
                  </div>
                -->

                <div class="columns">
                  <div class="column is-half">
                    <Field label="Minimum wallet seniority">
                      <div class="field-body">
                        <div class="field has-addons">
                          <Input type="text" disabled value="(Coming Soon)" expanded />
                          <p class="control">
                            <Button type="is-static">days</Button>
                          </p>
                        </div>
                      </div>
                    </Field>
                  </div>
                  <div class="column is-half">
                    <Field label="Maximum wallet seniority">
                      <div class="field-body">
                        <div class="field has-addons">
                          <Input type="text" disabled value="(Coming Soon)" expanded />
                          <p class="control">
                            <Button type="is-static">days</Button>
                          </p>
                        </div>
                      </div>
                    </Field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column is-half">
                    <Field label="Minimum balance">
                      <div class="field-body">
                        <div class="field has-addons">
                          <p class="control">
                            <Button type="is-static">$</Button>
                          </p>
                          <Input type="number" bind:value={campaign.audience.minBalance} expanded />
                        </div>
                      </div>
                    </Field>
                  </div>
                  <div class="column is-half">
                    <Field label="Maximum balance">
                      <div class="field-body">
                        <div class="field has-addons">
                          <p class="control">
                            <Button type="is-static">$</Button>
                          </p>
                          <Input type="number" bind:value={campaign.audience.maxBalance} expanded />
                        </div>
                      </div>
                    </Field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column is-half">
                    <Field label="Minimum number of tokens owned">
                      <Input type="number" bind:value={campaign.audience.minNumberOfTokens} />
                    </Field>
                  </div>
                  <div class="column is-half">
                    <Field label="Maximum number of tokens owned">
                      <Input type="number" bind:value={campaign.audience.maxNumberOfTokens} />
                    </Field>
                  </div>
                </div>

                <div class="columns">
                  <div class="column is-full">
                    <Field label="Owns token">
                      <Input type="text" disabled value="(Coming Soon)" />
                    </Field>
                  </div>
                </div>

                <hr />

                <div style:line-height="1.8rem">
                  <strong>Total addresses:</strong>
                  {#await segmentationPreviewPromise}
                    <Icon icon="spinner" customClass="fa-pulse" />
                  {:then addressesCount}
                    {addressesCount.toLocaleString()}
                  {:catch}
                    Unknown
                  {/await}
                </div>
              </OptionCard>
            {/if}
          </div>

          <div class="field is-grouped">
            <div class="control">
              <Button type="is-primary" nativeType="submit" loading={$saving} disabled={!campaign.audience.type}>Save</Button>
            </div>
            <div class="control">
              <Button on:click={() => $router.push({ name: 'campaignDetails', params: { id } })} disabled={$saving}>Cancel</Button>
            </div>
          </div>
        </fieldset>
      </form>
    {:catch error}
      <ErrorDisplay title="Failed to load campaign" {error} />
    {/await}
  </div>
</div>
