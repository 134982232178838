<script>
  import { Button, Icon, Notification, Toast, Dialog } from 'svelma-fixed'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Loader from '../components/Loader.svelte'
  import { createLoadingStore, combineLoadingStores, loadingProps } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import Tooltip from '../components/Tooltip.svelte'
  import { router } from '@spaceavocado/svelte-router'
  import BatchTags from '../components/BatchTags.svelte'
  import pluralize from 'pluralize'
  import html from 'html-template-tag'
  import { RouterLink } from '../router'
  import ImageDisplay from '../components/ImageDisplay.svelte'
  import { getAddressShortLabel, getOpenSeaUrl, getTxUrl, isDoneOrPending } from '../lib/utils'
  import fileDownload from 'js-file-download'

  export let campaignId
  export let batchId

  const loading = createLoadingStore()
  let loadingPromise

  let campaign
  let batch

  async function load () {
    await (loadingPromise = loading(async () => {
      campaign = await apiCall('GET', uri`/api/campaigns/${campaignId}`)
      batch = campaign.batches.find(b => b.id === batchId)
      if (!batch) throw new Error('Batch not found in campaign')
    }))
  }

  load()

  const deleting = createLoadingStore()

  const anyLoading = combineLoadingStores(loading, deleting)

  const downloadingImpressionsReport = createLoadingStore()

  async function deleteBatch () {
    await deleting(async () => {
      if (await Dialog.confirm({
        message: 'Are you sure you want to delete this batch?',
        type: 'is-danger'
      })) {
        await apiCall('DELETE', uri`/api/campaigns/${campaignId}/batches/${batchId}`)
        Toast.create({
          message: 'Batch deleted.',
          type: 'is-success'
        })
        $router.push({ name: 'campaignDetails', params: { id: campaignId } })
      }
    })
  }

  function duplicate () {
    $router.push({ name: 'newBatch', params: { id: campaignId }, query: { duplicateBatchId: batchId } })
  }

  function burn () {
    $router.push({ name: 'burnBatch', params: { campaignId, batchId } })
  }

  $: additionalMetadataKeys = Object.keys(batch?.metadata ?? {}).filter(k => !['image', 'name', 'description', 'external_url'].includes(k))

  function viewAdditionalMetadata () {
    Dialog.alert({
      title: 'Additional Metadata',
      message: html`<pre>${JSON.stringify(Object.fromEntries(Object.entries(batch.metadata).filter(([k]) => additionalMetadataKeys.includes(k))), null, 2)}</pre>`,
      type: 'is-info'
    })
  }

  async function fetchImpressions () {
    const { count } = await apiCall('GET', uri`/api/campaigns/${campaignId}/batches/${batchId}/impressions`)
    return count
  }

  async function downloadImpressionsReport () {
    await downloadingImpressionsReport(async () => {
      const count = await fetchImpressions()
      if (count > 100000) {
        if (!await Dialog.confirm({
          message: `You are about to download a report with ${count} rows. This may take a while. Are you sure you want to continue?`,
          type: 'is-warning',
          showCancel: true,
          confirmText: 'Download'
        })) {
          return
        }
      }

      const { csv } = await apiCall('GET', uri`/api/campaigns/${campaignId}/batches/${batchId}/impressions`, { csv: true })
      fileDownload(csv, `impressions-${batchId}.csv`)
    })
  }
</script>

<svelte:head>
	<title>{batch?.name ?? 'Batch Details'} - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <div class="level mb-0">
      <div class="level-left">
        <div class="level-item">
          <h4 class="title is-4">
            Batch {batch?.metadata?.name ? `"${batch.metadata?.name}"` : ''}
            <BatchTags {batch} />
          </h4>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          {#if batch}
            {#if !isDoneOrPending(batch.minting, true)}
              <Tooltip label="Edit"><Button type="is-white has-text-primary" on:click={() => $router.push({ name: 'editBatch', params: { campaignId, batchId: batch.id } })} disabled={$anyLoading}><Icon icon="pen" /></Button></Tooltip>
              <Tooltip label="Delete"><Button type="is-white has-text-danger" on:click={deleteBatch} {...loadingProps($anyLoading, $deleting)}><Icon icon="trash" /></Button></Tooltip>
            {:else if isDoneOrPending(batch.minting) && !isDoneOrPending(batch.burning)}
              <Tooltip label="Burn"><Button type="is-white has-text-danger" on:click={burn} disabled={$anyLoading}><Icon icon="fire" /></Button></Tooltip>
            {/if}
            <Tooltip label="Duplicate"><Button type="is-white" on:click={duplicate} disabled={$anyLoading}><Icon icon="copy" /></Button></Tooltip>
          {/if}
          <Tooltip label="Reload"><Button type="is-white" on:click={load} disabled={$anyLoading}><Icon icon="sync" /></Button></Tooltip>
        </div>
      </div>
    </div>

    <p class="mb-5">
      <RouterLink to={{ name: 'campaignDetails', params: { id: campaignId } }}><Icon icon="arrow-left" /> Back to campaign</RouterLink>
    </p>

    {#await loadingPromise}
      <Loader borderless />
    {:then}
      {#if ['scheduled', 'processing', 'pending'].includes(batch.minting?.status)}
        <Notification showClose={false}><Icon icon="info-circle" /> This batch currently has a <RouterLink to={{ name: 'mintBatch', params: { campaignId, batchId } }}>minting operation</RouterLink> in progress.</Notification>
      {/if}


      {#if batch.burning?.status === 'pending'}
        <Notification showClose={false}><Icon icon="info-circle" /> This batch currently has a <RouterLink to={{ name: 'burnBatch', params: { campaignId, batchId } }}>burning operation</RouterLink> in progress.</Notification>
      {/if}

      <div class="columns">
        <div class="column is-half">
          <div class="columns">
            <div class="column is-full">
              <strong>NFT Name</strong>
              <p>
                {batch.metadata?.name}
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <strong>NFT Description</strong>
              <p class="pre-wrap">
                {batch.metadata?.description}
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <strong>Target URL</strong>
              <p><a href={batch.metadata?.external_url?.replace(/\{campaign\}/g, campaign.id).replace(/\{id\}/g, batch.start ?? 0)} target="_blank" rel="noopener noreferrer">{batch.metadata?.external_url}</a></p>
            </div>
          </div>
          {#if additionalMetadataKeys.length}
            <div class="columns">
              <div class="column is-full">
                <strong>Additional Metadata</strong>
                <p>
                  <Tooltip label="View"><a href={undefined} on:click={viewAdditionalMetadata}>{additionalMetadataKeys.join(', ')}</a></Tooltip>
                </p>
              </div>
            </div>
          {/if}
          <div class="columns">
            <div class="column is-full">
              <strong>IDs</strong>
              <p>
                {#if isDoneOrPending(batch.minting, true)}
                  {#if batch.start != null && batch.end != null}
                    {#if batch.count === 1}
                      #{batch.start}
                    {:else}
                      #{batch.start} &ndash; #{batch.end - 1}
                    {/if}
                  {/if}
                  ({pluralize('token', batch.count, true)})
                {:else}
                  <span class="has-text-grey">(not yet minted)</span>
                {/if}
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <strong>Minting Transaction</strong>
              <p>
                {#if isDoneOrPending(batch.minting)}
                  <Tooltip label="View on Etherscan"><a href={getTxUrl(batch.minting.hash, campaign.network)} target="_blank" rel="noreferrer"><Icon icon="external-link-alt" /> {getAddressShortLabel(batch.minting.hash)}</a></Tooltip>
                {:else}
                  <span class="has-text-grey">(not yet minted)</span>
                {/if}
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <strong>Burning Transaction</strong>
              <p>
                {#if isDoneOrPending(batch.burning)}
                  <Tooltip label="View on Etherscan"><a href={getTxUrl(batch.burning.hash, campaign.network)} target="_blank" rel="noreferrer"><Icon icon="external-link-alt" /> {getAddressShortLabel(batch.burning.hash)}</a></Tooltip>
                {:else}
                  <span class="has-text-grey">(not yet burned)</span>
                {/if}
              </p>
            </div>
          </div>
          {#if isDoneOrPending(batch.minting)}
            <div class="columns">
              <div class="column is-full">
                <strong>OpenSea Impressions</strong>
                <p>
                  {#await fetchImpressions()}
                    <Icon icon="spinner" customClass="fa-pulse" />
                  {:then impressions}
                    {impressions.toLocaleString()}
                    {#if impressions > 0}
                      {#if $downloadingImpressionsReport}
                        <Icon icon="spinner" customClass="fa-pulse" />
                      {:else}
                        <Tooltip label="Download CSV Report"><a href={undefined} class="has-text-primary" on:click={() => downloadImpressionsReport()}><Icon icon="file-csv" /></a></Tooltip>
                      {/if}
                    {/if}
                  {:catch}
                    <span class="has-text-grey">(not available)</span>
                  {/await}
                </p>
              </div>
            </div>
          {/if}
        </div>
        <div class="column is-half">
          <div class="block mb-2">
            <strong>NFT Image</strong>
          </div>
          <div class="block">
            <ImageDisplay src={batch.metadata?.image} alt="NFT Media" />
          </div>
          {#if batch.minting?.status === 'success'}
            <a href={getOpenSeaUrl(campaign.contractAddress, batch.start, campaign.network)} target="_blank" rel="noreferrer"><Icon icon="external-link-alt" /> View first token on OpenSea</a>
          {/if}
        </div>
      </div>

      {#if !isDoneOrPending(batch.minting, true)}
        <hr />

        <Button is-fullwidth type="is-primary" iconLeft="coins" on:click={() => $router.push({ name: 'mintBatch', params: { campaignId, batchId } })}>Continue to Mint</Button>
      {/if}
    {:catch error}
      <ErrorDisplay title="Failed to load batch" {error} />
    {/await}
  </div>
</div>
