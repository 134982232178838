<script>
  import './styles/app.scss'
  import html from 'html-template-tag'

  import { onDestroy } from 'svelte'
  import Nav from './components/Nav.svelte'
  import { RouterView, router, shouldFixRouter } from './router'

  import Footer from './components/Footer.svelte'
  import ConnectOverlay from './components/ConnectOverlay.svelte'
  import { Toast } from 'svelma-fixed'

  import { appInfo, initAppInfo } from './lib/appInfo'
  import lastKnownVersionId from './stores/version'
  import { formatError } from './lib/utils'
  import dialogs from './stores/dialogs'

  import { apiCall } from './lib/api'
  import { GoogleAnalytics } from '@beyonk/svelte-google-analytics'

  window.tailorApiCall = apiCall // For debugging

  $lastKnownVersionId = window.appVariables.versionId // eslint-disable-line prefer-const
  $: if ($lastKnownVersionId !== window.appVariables.versionId) {
    console.log('Detected new version from another tab:', window.appVariables.versionId)
    location.reload()
  }

  // Workaround for https://github.com/spaceavocado/svelte-router/issues/13
  let navCount = 0
  let unregister = $router.onNavigationChanged((from, to) => {
    if (shouldFixRouter) {
      navCount++
      window.scrollTo(0, 0)
    }
  })

  onDestroy(() => {
    unregister()
    unregister = null
  })

  const appInfoPromise = (async () => {
    try {
      await Promise.all([
        (async () => {
          if (!appInfo.initialized) await initAppInfo()
        })()
      ])

      window.onerror = (message, file, row, col, error) => {
        if (message === 'ResizeObserver loop limit exceeded') return // Not sure where this error comes from
        Toast.create({ message: html`An error occured: ${formatError(error) ?? message}`, type: 'is-danger' })
      }

      window.onunhandledrejection = ({ reason }) => {
        if (reason?.stack?.includes('toLowerCase') && reason.stack.includes('updateBalances')) return // Web3-Onboard bug
        Toast.create({ message: html`An error occured: ${formatError(reason)}`, type: 'is-danger' })
      }

      document.body.classList.add('app-ready')
    } catch (e) {
      setTimeout(() => { throw e }, 0) // We want the global onerror to handle this one too
      throw e
    }
  })()

  $: console.log($router)
</script>

{#await appInfoPromise}
  <!-- Loading -->
{:then}
  {#if $router.currentRoute && !$router.currentRoute?.meta?.customLayout}
    <Nav />

    <div id="sticky-footer-outer-wrapper">
      <div id="sticky-footer-inner-wrapper">
        {#key navCount}
          <RouterView />
        {/key}
      </div>

      <Footer />
    </div>

    {#if !$router.currentRoute?.meta?.hideAccountCenter}
      <ConnectOverlay />
    {/if}

    {#if window.appVariables.gaMeasurementId}
      <GoogleAnalytics
        properties={[window.appVariables.gaMeasurementId]}
        configurations={{ [window.appVariables.gaMeasurementId]: {} }}
      />
    {/if}
  {:else}
    {#key navCount}
      <RouterView />
    {/key}
  {/if}
{:catch}
  <!-- onerror will show the error here -->
{/await}

{#each $dialogs as dialog (dialog.id)}
  <svelte:component this={dialog.Component} {...dialog.props} on:close={e => dialogs.close(dialog.id, e.detail)} />
{/each}
