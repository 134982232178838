import { chains as chainsForOnboard, TAILOR_CHAIN_ID } from '../stores/onboard'

const RAW = Symbol('raw')
const FALLBACK = Symbol('fallback')

export class Enumeration {
  constructor (values, fallback = key => key) {
    this[RAW] = values
    this[FALLBACK] = fallback
    Object.assign(this, values)
  }

  keys () {
    return Object.keys(this[RAW])
  }

  values () {
    return Object.values(this[RAW])
  }

  entries () {
    return Object.entries(this[RAW])
  }

  get rawObject () {
    return this[RAW]
  }

  getName (key) {
    return this[RAW][key] ?? this[FALLBACK](key)
  }
}

export const audienceTypes = new Enumeration({
  list: 'Simple list',
  segmented: 'Activity segmentation'
})

export const chains = new Enumeration(
  Object.fromEntries(chainsForOnboard.map(c => [c[TAILOR_CHAIN_ID], c.label])),
  key => `Unknown network (${key})`
)
