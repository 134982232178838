<script>
  import { disconnectAllWallets, connectWallet, walletConnecting, activeWalletAddress, ensureNetwork } from '../stores/onboard'
  import { onMount } from 'svelte'
  import { Button, Dialog, Icon, Toast } from 'svelma-fixed'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import Loader from '../components/Loader.svelte'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { formatError, getOpenSeaUrl } from '../lib/utils'
  import { scale } from 'svelte/transition'
  import { getTailorContract } from '../lib/tailorContract'
  import { authenticate } from '../stores/auth'
  import Tooltip from '../components/Tooltip.svelte'
  import ManualBurnInstructionsDialog from '../components/dialogs/ManualBurnInstructionsDialog.svelte'
  import UnsubscribeReasonDialog from '../components/dialogs/UnsubscribeReasonDialog.svelte'
  import dialogs from '../stores/dialogs'

  export let userId

  const submitting = createLoadingStore()

  onMount(async () => {
    await disconnectAllWallets()
  })

  async function reconnectWallet () {
    await disconnectAllWallets()
    await connectWallet()
  }

  let data

  async function load (address) {
    data = undefined
    data = await apiCall('GET', uri`/api/unsubscribe/${userId}/${address}`)
  }

  async function unsubscribe () {
    await submitting(async () => {
      const reason = await dialogs.open(UnsubscribeReasonDialog)
      if (reason == null) return

      const { authToken } = await authenticate(true) ?? {}
      if (!authToken) return
      await apiCall('POST', uri`/api/unsubscribe/${userId}/${$activeWalletAddress}/unsubscribe`, { reason }, authToken)

      if (data) data.isUnsubscribed = true

      Toast.create({
        message: 'Successfully unsubscribed.',
        type: 'is-success'
      })
    })
  }

  async function resubscribe () {
    await submitting(async () => {
      const { authToken } = await authenticate(true) ?? {}
      if (!authToken) return
      await apiCall('POST', uri`/api/unsubscribe/${userId}/${$activeWalletAddress}/resubscribe`, {}, authToken)

      if (data) data.isUnsubscribed = false

      Toast.create({
        message: 'Successfully resubscribed.',
        type: 'is-success'
      })
    })
  }

  async function burn (nft) {
    try {
      nft.burning = true
      data = data

      await ensureNetwork(nft.network)

      const contractInterface = getTailorContract(nft.contractAddress)
      const tx = await contractInterface.burn(nft.tokenId)
      console.log(`Burn TX for token ${nft.tokenId}`, tx)
      await tx.wait()

      if (data) data.nfts = data.nfts.filter(n => n !== nft)

      Toast.create({
        message: 'NFT was burned successfully.',
        type: 'is-success'
      })
    } catch (e) {
      console.error(e)
      if (e.code === 'ACTION_REJECTED') return
      return Dialog.alert({
        message: formatError(e),
        type: 'is-danger'
      })
    } finally {
      nft.burning = false
      data = data
    }
  }

  function showManualBurnInstructions () {
    dialogs.open(ManualBurnInstructionsDialog)
  }
</script>

<style lang="scss">
  .image {
    transition: filter 1s ease-in-out;
  }

  .bw {
    filter: grayscale(100%);
  }

  .card-image :global(.tooltip-wrapper) {
    display: flex; // Fix issue with some empty space below
  }
</style>

<svelte:head>
	<title>Unsubscribe - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4 has-text-centered">
    <h1 class="title is-1 my-5">
      Unsubscribe from NFTs from this collection owner
    </h1>

    <p class="my-5"><i class="big-icon fa fa-user-slash"></i></p>

    <p class="is-size-4 my-5">Are you sure you don't want to receive future NFT drops from this collection owner?</p>
    <p class="is-size-4 my-5">If you're certain that this collection's offers aren't relevant for you, you can connect your wallet, sign a message and be removed from future NFT drops.</p>
    <p class="is-size-4">You can also remove the NFTs from your wallet either by using Tailor's built-in burn function after connecting your wallet, or <a href={undefined} title="Show instructions" on:click={showManualBurnInstructions}>manually by sending to the burn address <Icon icon="question-circle" /></a>.</p>

    {#if $activeWalletAddress}
      <p class="my-5"><Icon icon="check-circle" /> Connected to wallet {$activeWalletAddress}</p>

      {#await load($activeWalletAddress)}
        <Loader borderless />
      {:then}
        {#if data.isUnsubscribed}
          <p class="my-5"><strong><Icon icon="check-circle" /> You have successfully unsubscribed.</p>
          <p class="my-5"><Button size="is-large" type="is-success" on:click={() => resubscribe()} loading={$walletConnecting || $submitting}>Resubscribe</Button></p>
        {:else}
          <p class="my-5"><Button size="is-large" type="is-danger" on:click={() => unsubscribe()} loading={$walletConnecting || $submitting}>Unsubscribe</Button></p>
        {/if}
      {:catch error}
        <ErrorDisplay title="Failed to load subscription status" {error} />
      {/await}
      <p><small><a href={undefined} on:click={() => reconnectWallet()}>Wrong wallet? Click here to connect a different wallet.</a></small></p>

      {#if data?.nfts?.length}
        <hr />

        <h4 class="title is-4">NFTs you received from this collection owner</h4>

        <div class="columns is-multiline is-centered">
          {#each data.nfts as nft ([nft.contractAddress, nft.tokenId, nft.network].join(':'))}
            <div class="column is-narrow">
              <div class="card" transition:scale>
                <div class="card-image">
                  <Tooltip label="View on OpenSea">
                    <a href="{getOpenSeaUrl(nft.contractAddress, nft.tokenId, nft.network)}" target="_blank" rel="noopener noreferrer">
                      <figure class="image" style:width="256px" class:bw={nft.burning}>
                        <img src={nft.imageUrl ?? '/images/nft_placeholder.png'} alt="NFT" on:error={e => (e.target.src = '/images/nft_placeholder.png')} />
                      </figure>
                    </a>
                  </Tooltip>
                </div>
                <footer class="card-footer">
                  {#if nft.burning}
                    <p class="card-footer-item"><Icon icon="spinner" customClass="fa-pulse" /></p>
                  {:else}
                    <a href={undefined} class="card-footer-item has-text-danger" on:click={() => burn(nft)}>Burn</a>
                  {/if}
                </footer>
              </div>
            </div>
          {/each}
        </div>
      {/if}
    {:else}
      <p class="my-5"><Button size="is-large" on:click={() => connectWallet()} loading={$walletConnecting}>Connect Wallet</Button></p>
    {/if}
  </div>
</div>
