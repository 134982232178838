// Fix https://github.com/christsim/multicoin-address-validator/issues/54
import { Buffer } from 'buffer'
globalThis.Buffer = Buffer

// Fix conflict between MetaMask and Guarda - prefer MetaMask
if (window.web3?.currentProvider && window.ethereum && window.web3?.currentProvider !== window.ethereum && window.web3.setProvider) {
  console.warn('MetaMask is not in web3.currentProvider - fixing')
  window.web3.setProvider(window.ethereum)
}

// Hotfix for https://github.com/spaceavocado/svelte-router/issues/16 which works because tc.isNumber is used only in this one place.
import tc from '@spaceavocado/type-check'
const oldIsNumber = tc.isNumber
tc.isNumber = function (s) {
  if (s === undefined) return true
  return oldIsNumber(s)
}
