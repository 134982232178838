<script>
  import { Button, Icon } from 'svelma-fixed'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Loader from '../components/Loader.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { router } from '../router'
  import { apiCall } from '../lib/api'
  import CampaignList from '../components/CampaignList.svelte'
  import Tooltip from '../components/Tooltip.svelte'

  const loading = createLoadingStore()
  let loadingPromise

  let campaigns = []

  async function load () {
    await (loadingPromise = loading(async () => {
      campaigns = await apiCall('GET', '/api/campaigns')
    }))
  }

  load()
</script>

<svelte:head>
	<title>Campaigns - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h4 class="title is-4">
            <span class="mr-3">Campaigns</span>
            <Button size="is-small" type="is-primary" iconLeft="plus" on:click={() => $router.push({ name: 'newCampaign' })}>Create New</Button>
          </h4>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <Tooltip label="Reload"><Button type="is-white" on:click={load} disabled={$loading}><Icon icon="sync" /></Button></Tooltip>
        </div>
      </div>
    </div>

    {#await loadingPromise}
      <Loader borderless />
    {:then}
      <CampaignList {campaigns} />
    {:catch error}
      <ErrorDisplay title="Failed to load campaigns" {error} />
    {/await}
  </div>
</div>
