<script>
  export let src
  export let alt
</script>

<style lang="scss">
  a {
    display: inline-block;
    cursor: zoom-in;
  }

  .image {
    @import "bulma/sass/utilities/mixins.sass";

    width: 350px;
    height: 350px;
    border: 1px solid $border;
    background: #eee url('data:image/svg+xml,\<svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" fill-opacity=".25"><rect x="200" width="200" height="200" /><rect y="200" width="200" height="200" /></svg>');
    background-size: 35px 35px;

    @include touch {
      width: 250px;
      height: 250px;
      background-size: 25px 25px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
</style>

<a href={src || 'about:blank'} target="_blank" rel="noreferrer">
  <figure class="image">
    <img {src} {alt} on:error={e => (e.target.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>')} />
  </figure>
</a>
