<script>
  import { activeWalletAddress, connectWallet, expandAccountCenter, walletConnecting, walletRestoring } from '../stores/onboard'
  import { Button, Icon } from 'svelma-fixed'
  import { authenticate, authenticateLoading, signOut, userInfo } from '../stores/auth'
  import { fade } from 'svelte/transition'
  import { getAddressShortLabel } from '../lib/utils'
  import { tick } from 'svelte'
  import AutoUpdate from './AutoUpdate.svelte'

  let overlayType
  let overlayVisible = false

  let tickCounter

  $: {
    // Ensure token expiration timer is checked regularly
    tickCounter // eslint-disable-line no-unused-expressions

    let newOverlayType = null
    if ($walletRestoring) {
      newOverlayType = 'loader'
    } else if ($userInfo) {
      if (!$activeWalletAddress) {
        newOverlayType = 'reconnectWallet'
      } else if ($userInfo.address !== $activeWalletAddress) {
        newOverlayType = 'accountMismatch'
      } else if ($userInfo.tokenExpiration < Date.now() + 86400000) {
        newOverlayType = 'reauthenticate'
      }
    }

    if (newOverlayType !== overlayType) {
      overlayType = newOverlayType
    }

    if (overlayType) {
      overlayVisible = true
    } else if (overlayVisible) {
      // This is done to allow one final content update before hiding the overlay, to replace contents with loader
      tick().then(() => {
        overlayVisible = false
      })
    }
  }
</script>

<style lang="scss">
  main {
    position: fixed;
    top: 56px; // Navbar height
    left: 0;
    width: 100%;
    height: calc(100% - 56px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(40,40,40,0.5) 100%);
    backdrop-filter: blur(4px);
    z-index: 29;
  }
</style>

{#if overlayVisible}
  <main transition:fade class="has-text-centered p-1">
    {#if overlayType === 'reconnectWallet'}
      <h1 class="title is-1 my-5">
        Reconnect your wallet
      </h1>

      <p class="my-5"><i class="big-icon fa fa-wallet"></i></p>

      <p class="is-size-4">You are currently signed into Tailor as <strong>{getAddressShortLabel($userInfo.address)}</strong>, but the corresponding wallet is not connected.</p>
      <p class="is-size-4">Connect wallet {getAddressShortLabel($userInfo.address)} to continue or sign out.</p>

      <p class="my-5"><Button size="is-large" type="is-primary" on:click={() => connectWallet()} loading={$walletConnecting}>Connect Wallet</Button></p>

      <p><small><a href={undefined} on:click={() => signOut()}><Icon icon="sign-out" />Sign out</a></small></p>
    {:else if overlayType === 'accountMismatch'}
      <h1 class="title is-1 my-5">
        Account switched
      </h1>

      <p class="my-5"><i class="big-icon fa fa-exchange-alt"></i></p>

      <p class="is-size-4">You are currently signed into Tailor as <strong>{getAddressShortLabel($userInfo.address)}</strong>, but you are connected to wallet <strong>{getAddressShortLabel($activeWalletAddress)}</strong>.</p>
      <p class="is-size-4">To continue, you can either verify ownership of {getAddressShortLabel($activeWalletAddress)} to use it with Tailor or reconnect wallet {getAddressShortLabel($userInfo.address)}.</p>

      <p class="my-5">
        <Button size="is-large" type="is-primary" on:click={() => authenticate()} loading={$authenticateLoading}>Sign In As {getAddressShortLabel($activeWalletAddress)}</Button>
        <Button size="is-large" outlined on:click={() => expandAccountCenter()} disabled={$authenticateLoading}>Switch Wallet</Button>
      </p>
    {:else if overlayType === 'reauthenticate'}
      <h1 class="title is-1 my-5">
        Reauthenticate your wallet
      </h1>

      <p class="my-5"><i class="big-icon fa fa-user-clock"></i></p>

      <p class="is-size-4">Your session is about to expire.</p>
      <p class="is-size-4">In order to continue using Tailor, you need to verify ownership of the wallet {getAddressShortLabel($userInfo.address)} again.</p>

      <p class="my-5"><Button size="is-large" type="is-primary" on:click={() => authenticate()} loading={$authenticateLoading}>Verify Now</Button></p>

      <p><small><a href={undefined} on:click={expandAccountCenter}>Wrong wallet? Click here to switch or disconnect the wallet.</a></small></p>
    {:else if !$walletRestoring && $activeWalletAddress && $userInfo?.address === $activeWalletAddress}
      <Icon icon="check" size="is-large" />
    {:else}
      <Icon icon="spinner" size="is-large" customClass="fa-pulse" />
    {/if}
  </main>
{/if}

<AutoUpdate interval={60000} bind:tickCounter />
