export async function getTxStatusByHash (hash) {
  if (!window?.ethereum) throw new Error('No Ethereum provider found')

  const tx = await window.ethereum.request({
    method: 'eth_getTransactionByHash',
    params: [hash]
  })

  if (!tx) return { hash, status: 'dropped' }

  const receipt = await window.ethereum.request({
    method: 'eth_getTransactionReceipt',
    params: [hash]
  })

  if (!receipt) return { hash, tx, status: 'pending' }

  const ok = Number(receipt.status) === 1

  return { hash, tx, receipt, status: ok ? 'success' : 'failed' }
}
