import { writable, get, derived } from 'svelte/store'
import { apiCall, StatusCodeError } from '../lib/api'
import { activeWalletAddress, getProvider, onboard } from './onboard'
import { createLoadingStore } from './loading'
import { router } from '../router'

// This is intentionally loaded from local storage only once on startup, so that different tabs could use different accounts as long as the wallets are connected
export const currentAuthToken = writable(window.localStorage.tailorAuthToken ?? null)
export const userInfo = writable(null)
export const accountIsMatching = derived([userInfo, activeWalletAddress], ([$userInfo, $activeWalletAddress]) => ($userInfo?.address ?? null) === ($activeWalletAddress ?? null))

let wasConnected = false
let previousSignedInUser = null

activeWalletAddress.subscribe(async address => {
  if (address) {
    wasConnected = true
  } else if (wasConnected) {
    wasConnected = false
    signOut()
  }
})

currentAuthToken.subscribe(token => {
  if (token) {
    window.localStorage.tailorAuthToken = token
  } else {
    window.localStorage.removeItem('tailorAuthToken')
  }
})

export const authenticateLoading = createLoadingStore()

export async function authenticate (temporary = false) {
  return await authenticateLoading(async () => {
    const provider = getProvider()
    const signer = provider.getSigner()
    const address = await signer.getAddress()

    const { message } = await apiCall('POST', '/api/auth/prepare', { address })

    const { update, dismiss } = onboard.state.actions.customNotification({
      type: 'pending',
      message: 'Verification pending. Confirm the signing request in your wallet to continue.',
      autoDismiss: 0
    })

    let signature
    try {
      signature = await signer.signMessage(message)

      update({
        eventCode: 'authSuccess',
        message: 'Wallet ownership verified!',
        type: 'success',
        autoDismiss: 5000
      })
      setTimeout(dismiss, 5000) // Not sure why autoDismiss is broken...?
    } catch (e) {
      if (e.code === 'ACTION_REJECTED') {
        dismiss()
        return null
      }

      update({
        eventCode: 'authFailed',
        message: 'Failed to verify wallet ownership! Please try again.',
        type: 'error',
        autoDismiss: 5000
      })
      setTimeout(dismiss, 5000) // Not sure why autoDismiss is broken...?
      throw e
    }

    const result = await apiCall('POST', '/api/auth/submit', { address, message, signature, temporary })
    if (!temporary) {
      currentAuthToken.set(result.authToken)
      userInfo.set(result.userInfo)
    }
    return result
  })
}

export function signOut () {
  currentAuthToken.set(null)
  userInfo.set(null)
}

export async function updateUserInfo () {
  if (!get(currentAuthToken)) return
  try {
    const result = await apiCall('GET', '/api/auth/user')
    userInfo.set(result)
  } catch (e) {
    if (e instanceof StatusCodeError && e.response.status === 401) {
      console.warn('User info update failed, user is not authenticated', e)
      currentAuthToken.set(null)
      userInfo.set(null)
    } else {
      console.warn('User info update failed', e)
    }
  }
}

userInfo.subscribe(userInfo => {
  if (userInfo) {
    if (previousSignedInUser && previousSignedInUser !== userInfo.id) {
      get(router).push({ name: 'HOME' })
    }
    previousSignedInUser = userInfo.id
  } else {
    previousSignedInUser = null
  }
})

let interval
export function initUserUpdate () {
  if (interval) return
  interval = setInterval(updateUserInfo, 60000)
}
