<script>
  import { slide } from 'svelte/transition'

  export let name
  export let value
  export let group
  export let icon
  export let iconPack = 'fas'
  export let disabled = false
</script>

<style lang="scss">
  .card-header-title {
    display: block;

    :global(.font-normal) {
      font-weight: normal;
    }
  }

  .radio {
    line-height: 2rem;

    > * {
      vertical-align: middle;
    }

    > span {
      display: inline-block;
      margin-left: 0.5rem;
    }
  }

  .oc-subtitle {
    font-weight: normal;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    font-size: 0.75rem;
    color: $grey;
  }
</style>

<div class="card block">
  <header class="card-header">
    <div class="card-header-title">
      <div class="oc-title">
        <label class="radio" class:has-cursor-not-allowed={disabled}>
          <input type="radio" {name} on:click bind:group {disabled} {value}>
          <span class:has-text-grey={disabled}><slot name="title" /></span>
        </label>
      </div>
      {#if $$slots.subtitle}
        <div class="oc-subtitle">
          <slot name="subtitle" />
        </div>
      {/if}
    </div>
    {#if icon}
      <button class="card-header-icon">
        <span class="icon">
          <i class="{iconPack} fa-{icon}" aria-hidden="true"></i>
        </span>
      </button>
    {/if}
  </header>
  {#if group === value}
    <div class="card-content" transition:slide|local>
      <slot />
    </div>
  {/if}
</div>
