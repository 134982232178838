<script>
  import { Button, Dialog, Field, Input, Toast } from 'svelma-fixed'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Loader from '../components/Loader.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import { formatError } from '../lib/utils'
  import { toChecksumAddress } from 'ethereum-checksum-address'

  const loading = createLoadingStore()
  let loadingPromise

  let unsubscribedAddresses

  async function load () {
    await (loadingPromise = loading(async () => {
      ;({ unsubscribedAddresses } = await apiCall('GET', '/api/auth/user', { withUnsubscribedAddresses: true }))
    }))
  }

  load()

  const saving = createLoadingStore()

  async function save () {
    try {
      unsubscribedAddresses = [...new Set((unsubscribedAddresses ?? []).map(a => toChecksumAddress(a)))]
    } catch (e) {
      console.error(e)
      return Dialog.alert({
        message: formatError(e),
        type: 'is-danger'
      })
    }

    await saving(async () => {
      ;({ unsubscribedAddresses } = await apiCall('PATCH', uri`/api/auth/user`, {
        unsubscribedAddresses
      }))
    })

    Toast.create({
      message: 'Settings saved.',
      type: 'is-success'
    })
  }

  function updateAddresses (e) {
    unsubscribedAddresses = e.target.value.split(/\n/).map(x => x.trim()).filter(x => x)
  }
</script>

<svelte:head>
	<title>Settings - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      Settings
    </h4>
    {#await loadingPromise}
      <Loader borderless />
    {:then}
      <form on:submit|preventDefault={save}>
        <fieldset disabled={$saving}>
          <Field label="Unsubscribed Addresses" message="These addresses will never be considered as part of any audience. Users can use the unsubscribe link to add themselves to this list.">
            <Input type="textarea" rows={10} value={(unsubscribedAddresses ?? []).map(x => x + '\n').join('')} on:change={updateAddresses} />
          </Field>

          <div class="field is-grouped">
            <div class="control">
              <Button type="is-primary" nativeType="submit" loading={$saving}>Save</Button>
            </div>
          </div>
        </fieldset>
      </form>
    {:catch error}
      <ErrorDisplay title="Failed to load settings" {error} />
    {/await}
  </div>
</div>
