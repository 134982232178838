<script>
  import ModalDialog from '../ModalDialog.svelte'
</script>

<ModalDialog on:close title="Manual NFT Removal" closeable>
  <div class="content">
    <p>
      If you are unable to remove your NFT through Tailor by connecting your wallet, you can manually remove it by following these steps:
    </p>
    <ol>
      <li>Open your wallet and go to the NFTs list.</li>
      <li>Find the NFT you want to remove.</li>
      <li>Transfer the NFT to the following address:<br><code>0x000000000000000000000000000000000000dEaD</code></li>
    </ol>
    <p>
      Once the transaction is confirmed, your NFT will be removed.
    </p>
  </div>
</ModalDialog>
