<script>
  import { Button, Icon, Notification } from 'svelma-fixed'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Loader from '../components/Loader.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import TaskList from '../components/TaskList.svelte'
  import Tooltip from '../components/Tooltip.svelte'
  import { chains } from '../lib/enums'
  import { formatCurrency } from '../lib/utils'
  import dialogs from '../stores/dialogs'
  import BalanceKeeperDialog from '../components/dialogs/BalanceKeeperDialog.svelte'
  import { userInfo } from '../stores/auth'

  const loading = createLoadingStore()
  let loadingPromise

  let tasks = []
  let balances = {}

  async function load () {
    await (loadingPromise = loading(async () => {
      ;[tasks, balances] = await Promise.all([
        apiCall('GET', '/api/tasks'),
        apiCall('GET', '/api/balances', { withBalanceCheck: true })
      ])
    }))
  }

  load()

  async function openDepositDialog (network, amount) {
    await dialogs.open(BalanceKeeperDialog, { network, mode: 'deposit', initialAmount: amount })
    load()
  }

  async function openWithdrawDialog (network) {
    await dialogs.open(BalanceKeeperDialog, { network, mode: 'withdraw' })
    load()
  }
</script>

<svelte:head>
	<title>Scheduled Tasks - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h4 class="title is-4">
            <span class="mr-3">Scheduled Tasks</span>
          </h4>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <Tooltip label="Reload"><Button type="is-white" on:click={load} disabled={$loading}><Icon icon="sync" /></Button></Tooltip>
        </div>
      </div>
    </div>

    {#await loadingPromise}
      <Loader borderless />
    {:then}
      <p>Scheduled tasks are initiated from our server instead of your wallet and hence need funds deposited in advance to be able to execute.</p>
      <p>Here you can deposit and withdraw funds used for scheduled tasks.</p>

      <div class="columns mt-3">
        {#each chains.entries() as [key, label]}
          {#if balances[key] && (key === 'mainnet' || $userInfo.poweruser)}
            <div class="column is-half">
              <strong>Funds on {label}</strong>
              <p>
                {balances[key].balanceKeeper.balance.toFixed(6)} {balances[key].tokenName} ({formatCurrency(balances[key].balanceKeeper.balanceUsd, 'USD', 2)})
              </p>
              <p>
                <Button size="is-small" type="is-primary" iconLeft="upload" on:click={() => openDepositDialog(key)}>Deposit</Button>
                <Button size="is-small" type="is-primary" iconLeft="download" on:click={() => openWithdrawDialog(key)} disabled={balances[key].balanceKeeper.balanceWei <= 0}>Withdraw</Button>
              </p>
            </div>
          {/if}
        {/each}
      </div>

      {#each chains.entries() as [key, label]}
        {#if balances[key]?.depositRecommendation > 0}
          <Notification type="is-danger" class="mt-3">
            <strong>Insufficient funds on {label}!</strong>
            <p>
              Please deposit at least {balances[key].depositRecommendation.toFixed(6)} {balances[key].tokenName} ({formatCurrency(balances[key].depositRecommendation * balances[key].ethPrice, 'USD', 2)}) to allow for all scheduled tasks to be executed.
            </p>
            <Button type="is-danger" inverted outlined on:click={() => openDepositDialog(key, balances[key].depositRecommendation)}>Deposit Now</Button>
          </Notification>
        {/if}
      {/each}

      <hr />

      <TaskList {tasks} {balances} />
    {:catch error}
      <ErrorDisplay title="Failed to load tasks" {error} />
    {/await}
  </div>
</div>
