<script>
  import { isDoneOrPending } from '../lib/utils'

  export let batch
</script>

{#if batch && !isDoneOrPending(batch.minting, true)}
  <div class="tag is-light is-warning ml-2 is-rounded">Draft</div>
{/if}
{#if ['scheduled', 'processing', 'pending'].includes(batch?.minting?.status)}
  <div class="tag is-light is-info ml-2 is-rounded">Minting</div>
{/if}
{#if batch && isDoneOrPending(batch.burning)}
  <div class="tag is-black ml-2 is-rounded">{batch.burning.status === 'success' ? 'Burned' : 'Burning'}</div>
{/if}
