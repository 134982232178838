<script>
  export let campaignId
  export let operation
  export let numberOfTokens = 1

  export let allowScheduledTask = false
  export let scheduledTask = false

  export let gasOptions = {
    maxFeePerGas: undefined,
    maxPriorityFeePerGas: undefined
  }

  import Tooltip from './Tooltip.svelte'
  import { Button, Icon } from 'svelma-fixed'

  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import { userInfo } from '../stores/auth'
  import uri from 'uri-tag'
  import AutoUpdate from './AutoUpdate.svelte'
  import ErrorDisplay from './ErrorDisplay.svelte'
  import FeesWidgetRow from './FeesWidgetRow.svelte'
  import pluralize from 'pluralize'

  const loading = createLoadingStore()
  let loadingPromise

  let fees

  let percentage = 100
  let maxFeePerGas
  let maxPriorityFeePerGas

  $: {
    if (fees) {
      if (scheduledTask) {
        maxFeePerGas = fees.gasPriceInfo.maxFeePerGas * percentage / 100
        // TODO: Remove maxPriorityFeePerGas, it is not used anyway for the scheduled task
        if (percentage > 100) {
          maxPriorityFeePerGas = fees.gasPriceInfo.maxPriorityFeePerGas * (100 + (percentage - 100) * 0.5) / 100
        } else {
          maxPriorityFeePerGas = fees.gasPriceInfo.maxPriorityFeePerGas
        }
        gasOptions = {
          maxFeePerGas: (maxFeePerGas * 1e9).toFixed(0),
          maxPriorityFeePerGas: (maxPriorityFeePerGas * 1e9).toFixed(0)
        }
      } else {
        maxFeePerGas = undefined
        maxPriorityFeePerGas = undefined
        gasOptions = {
          maxFeePerGas: undefined,
          maxPriorityFeePerGas: undefined
        }
      }
    }
  }

  async function load () {
    await loading(async () => {
      // Note: Using user info and not onboard wallet info because this may be loaded while the connect overlay is shown.
      fees = await apiCall('GET', uri`/api/campaigns/${campaignId}/fees`, { sender: $userInfo.address })
    })
  }

  loadingPromise = load()
</script>

<style lang="scss">
  main {
    box-shadow: none;
    border: 1px solid $border;
    display: inline-block;

    text-align: left;
    width: 100%;
    max-width: var(--max-width, none);
  }

  .gas-price-section {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
  }
</style>

<main class="box my-4">
  <div class="level is-mobile">
    <div class="level-left">
      <strong>Fee Estimation</strong>
    </div>
    <div class="level-right">
      <Tooltip label="Reload"><Button size="is-small" type="is-white" on:click={() => (loadingPromise = load())} disabled={$loading}><Icon icon="sync" /></Button></Tooltip>
    </div>
  </div>

  {#await loadingPromise}
    <Icon icon="spinner" customClass="fa-pulse" /> Loading fee information...
  {:then}
    {#if operation === 'deploy'}
      <FeesWidgetRow {fees} {maxFeePerGas} gas={fees.gasUsage.deploy}>
        Gas fee
      </FeesWidgetRow>

      <FeesWidgetRow {fees} {maxFeePerGas} gas={fees.gasUsage.deploy} total />
    {:else if operation === 'mint' || operation === 'burn'}
      <FeesWidgetRow {fees} {maxFeePerGas} gas={fees.gasUsage[operation].base}>
        Gas fee base
      </FeesWidgetRow>
      <FeesWidgetRow {fees} {maxFeePerGas} gas={fees.gasUsage[operation].perToken} quantity={numberOfTokens} let:singleEthValueFormatted>
        Gas fee per token ({pluralize('token', numberOfTokens, true)} à {singleEthValueFormatted})
      </FeesWidgetRow>

      {@const tailorFee = fees[`${operation}Fee`]}
      {#if tailorFee}
        <FeesWidgetRow {fees} {maxFeePerGas} ethValue={tailorFee} quantity={numberOfTokens} strikeThrough={fees.isAdmin} let:singleEthValueFormatted>
          Tailor fee ({pluralize('token', numberOfTokens, true)} à {singleEthValueFormatted})
          {#if fees.isAdmin}
            <div class="tag is-rounded is-info">Admin</div>
          {/if}
        </FeesWidgetRow>
      {/if}

      {#if scheduledTask}
        <FeesWidgetRow {fees} {maxFeePerGas} gas={fees.gasUsage.scheduledTask}>
          Scheduled task gas fee
        </FeesWidgetRow>
      {/if}

      <FeesWidgetRow {fees} {maxFeePerGas} gas={fees.gasUsage[operation].base + numberOfTokens * fees.gasUsage[operation].perToken + (scheduledTask ? fees.gasUsage.scheduledTask : 0)} ethValue={fees.isAdmin ? 0 : tailorFee * numberOfTokens} total />
    {/if}

    {#if allowScheduledTask}
      {#if scheduledTask}
        <div class="gas-price-section">
          <span>Schedule to execute at lower gas price:</span>
          <input class="range is-primary" type="range" min="1" max="100" step="3" bind:value={percentage} />
          <Button size="is-small" type="is-link is-light" on:click={() => (scheduledTask = false)}>Cancel</Button>
        </div>
      {:else}
        <a href={undefined} on:click={() => { scheduledTask = true; percentage = 50 }}>
          <Icon icon="cog" /> Schedule for execution at lower gas price
        </a>
      {/if}
    {/if}
  {:catch error}
    <ErrorDisplay title="Failed to load fee information" {error} />
  {/await}
</main>

<AutoUpdate interval={30000} skipFirstTick on:tick={() => load().catch(e => console.error(e))} />
