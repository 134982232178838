import { writable } from 'svelte/store'
import { generateRandomString } from '../lib/utils'

const dialogs = writable([])

dialogs.open = (Component, props) => {
  const id = generateRandomString(6)
  let onClose
  const promise = new Promise(resolve => { onClose = resolve })
  dialogs.update(dialogs => [...dialogs, { id, Component, props, onClose }])
  return promise
}

dialogs.close = (id, result) => {
  dialogs.update(dialogs => {
    const dialog = dialogs.find(dialog => dialog.id === id)
    if (dialog) dialog.onClose(result)
    return dialogs.filter(dialog => dialog.id !== id)
  })
}

export default dialogs
