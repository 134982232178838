<script>
  import { Button, Input, Icon } from 'svelma-fixed'
  import { apiCall } from '../lib/api'
  import { createLoadingStore } from '../stores/loading'

  let password = ''
  let incorrect = false

  const submitting = createLoadingStore()

  async function submit () {
    incorrect = false
    await submitting(async () => {
      localStorage.tailorBetaPassword = password
      const { incorrectBetaPassword } = await apiCall('GET', '/api/info')
      if (!incorrectBetaPassword) {
        window.location.href = '/'
        await new Promise(resolve => {}) // We wait for navigation and never resolve this promise
      } else {
        incorrect = true
      }
    })
  }
</script>

<style lang="scss">
  p :global(input) {
    max-width: 300px;
  }
</style>

<svelte:head>
	<title>Beta Login - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4 has-text-centered">
    <h1 class="title is-1 my-5">
      Welcome to Tailor!
    </h1>

    <p class="my-5"><i class="big-icon fa fa-lock"></i></p>

    <p class="is-size-4">Tailor is currently in private beta.</p>
    <p class="is-size-4">In order to access this application, you need to provide your beta password below.</p>

    <form on:submit|preventDefault={submit}>
      <p class="my-5"><Input type="password" bind:value={password} autofocus disabled={$submitting} /></p>

      {#if incorrect}
        <p class="has-text-danger is-size-4"><Icon icon="exclamation-triangle" />The password you provided was not correct. Please try again.</p>
      {/if}

      <p class="my-5"><Button size="is-large" type="is-primary" loading={$submitting} disabled={!password} nativeType={submit}>Submit</Button></p>
    </form>
  </div>
</div>
