import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import coinbaseModule from '@web3-onboard/coinbase'
import trezorModule from '@web3-onboard/trezor'
import ledgerModule from '@web3-onboard/ledger'
import walletConnectModule from '@web3-onboard/walletconnect'
import { createLoadingStore } from './loading'
import { writable, derived, get } from 'svelte/store'
import { ethers } from 'ethers'
import { toChecksumAddress } from 'ethereum-checksum-address'
import { delay } from '../lib/utils'

export const TAILOR_CHAIN_ID = Symbol('TAILOR_CHAIN_ID')

export const chains = [{
  id: '0x1',
  token: 'ETH',
  label: 'Ethereum Mainnet',
  rpcUrl: window.appVariables.ethNodeUrls.mainnet,
  [TAILOR_CHAIN_ID]: 'mainnet'
}, {
  id: '0x5',
  token: 'GoerliETH',
  label: 'Goerli Testnet',
  rpcUrl: window.appVariables.ethNodeUrls.goerli,
  color: '#9925be',
  [TAILOR_CHAIN_ID]: 'goerli'
}]

export const chainsByTailorChainId = Object.fromEntries(chains.map(c => [c[TAILOR_CHAIN_ID], c]))

const mq = window.matchMedia('(max-width: 1023px)')

export const onboard = Onboard({
  wallets: [
    injectedModule(),
    coinbaseModule(),
    trezorModule({
      email: 'support@tailor.network',
      appUrl: 'https://tailor.network'
    }),
    ledgerModule(),
    walletConnectModule()
  ],
  chains,
  apiKey: window.appVariables.blocknativeApiKey,
  appMetadata: {
    name: 'Tailor',
    icon: '/images/logo.svg',
    description: 'Decentralized & Personalized NFT Campaigns',
    recommendedInjectedWallets: [
      { name: 'MetaMask', url: 'https://metamask.io' },
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
      { name: 'Exodus', url: 'https://www.exodus.com/' }
    ]
  }
})

const onMediaChange = e => {
  onboard.state.actions.updateAccountCenter({
    minimal: e.matches
  })
}

if (mq.addEventListener) {
  mq.addEventListener('change', onMediaChange)
} else {
  mq.addListener(onMediaChange)
}

onMediaChange({ matches: mq.matches })

let previousWallets
try {
  previousWallets = JSON.parse(window.localStorage.tailorBlocknativeConnectedWallets)
} catch {}

export const walletConnecting = createLoadingStore()
export const walletRestoring = createLoadingStore()

export async function connectWallet (options) {
  return await walletConnecting(async () => {
    return await onboard.connectWallet(options)
  })
}

export async function disconnectAllWallets () {
  return await walletConnecting(async () => {
    for (const wallet of onboard.state.get().wallets) {
      await onboard.disconnectWallet({ label: wallet.label })
    }
  })
}

export async function restoreWalletConnection () {
  if (previousWallets?.length) {
    await walletRestoring(async () => {
      try {
        await connectWallet({
          autoSelect: { label: previousWallets[0], disableModals: true }
        })
      } catch (e) {
        console.error('Failed to reconnect wallet', previousWallets[0], e)
      }
    })
  }

  previousWallets = undefined
}

const prevChainId = window.localStorage.tailorBlocknativeChainId ?? chains[0].id
export let currentChainVal = chains.find(c => c.id === prevChainId)
export const currentChain = writable(currentChainVal)

export const onboardState = writable(onboard.state.get())
onboard.state.select().subscribe(state => {
  onboardState.set(state)
})

onboardState.subscribe(({ wallets }) => {
  if (previousWallets?.length && !wallets.length) return
  window.localStorage.tailorBlocknativeConnectedWallets = JSON.stringify(wallets.map(({ label }) => label))

  const chainId = wallets[0]?.chains[0]?.id
  if (chainId && currentChainVal.id !== chainId) {
    currentChainVal = chains.find(({ id }) => id === chainId) ?? {
      id: chainId,
      token: 'ETH',
      label: `Unknown network (${chainId})`,
      [TAILOR_CHAIN_ID]: chainId
    }
    window.localStorage.tailorBlocknativeChainId = chainId
    currentChain.set(currentChainVal)
  }
})

export const activeWallet = derived(onboardState, $onboardState => $onboardState.wallets[0])
export const activeWalletAddress = derived(activeWallet, $activeWallet => {
  const address = $activeWallet?.accounts[0]?.address
  if (!address) return null
  return toChecksumAddress(address)
})

export function getProvider (throwIfNoWallet = true) {
  if (throwIfNoWallet) {
    const provider = getProvider(false)
    if (!provider) throw new Error('No valid wallet connected')
    return provider
  } else {
    const { wallets } = onboard.state.get()
    if (!wallets.length) return undefined
    return new ethers.providers.Web3Provider(wallets[0].provider, 'any')
  }
}

export function expandAccountCenter () {
  setTimeout(() => onboard.state.actions.updateAccountCenter({ expanded: true }), 0)
}

export async function ensureNetwork (network) {
  const onboardChain = chains.find(c => c[TAILOR_CHAIN_ID] === network)
  if (!onboardChain) throw new Error(`Unknown network "${network}"`)
  if (currentChainVal[TAILOR_CHAIN_ID] !== network) {
    await onboard.setChain({ chainId: onboardChain.id })
    await delay(1000)
  }
  if (currentChainVal[TAILOR_CHAIN_ID] !== network) {
    throw new Error(`Failed to switch to required network! To continue, please switch your wallet to ${onboardChain.label} manually.`)
  }
}

export async function sendTx (txDetails, withPreflightNotifications = false) {
  const ethersProvider = getProvider(true)
  const signer = ethersProvider.getSigner()

  const wallet = get(activeWallet)
  if (!wallet) throw new Error('No wallet connected')

  const sendTransaction = async () => {
    console.log('Sending TX', txDetails)
    const tx = await signer.sendTransaction(txDetails)
    console.log('TX result', tx)
    return tx.hash
  }

  let transactionHash
  if (withPreflightNotifications) {
    const { balance } = wallet.accounts[0]
    const balanceValue = Object.values(balance)[0]

    const gasPrice = async () => {
      const val = await ethersProvider.getGasPrice()
      return val.toString()
    }

    const estimateGas = async () => {
      const gas = await ethersProvider.estimateGas(txDetails)
      return gas.toString()
    }

    transactionHash = await onboard.state.actions.preflightNotifications({
      sendTransaction,
      gasPrice,
      estimateGas,
      balance: balanceValue,
      txDetails
    })
  } else {
    transactionHash = await sendTransaction()
  }

  console.log('TX hash', transactionHash)

  return transactionHash
}
