<style lang="scss">
  .details-table > :global(*) {
    border-bottom: 1px solid $border;
    margin: 0;
    padding: 0.5rem 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .details-table > :global(.level) {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .details-table > :global(.level > *) {
    max-width: 100%;
  }

  .details-table > :global(.level > * > .level-item) {
    flex-shrink: 1;
  }
</style>

<div class="details-table">
  <slot />
</div>
