<script>
  import { connectWallet, activeWalletAddress, walletConnecting, walletRestoring, expandAccountCenter } from '../stores/onboard'
  import { authenticate, authenticateLoading, userInfo } from '../stores/auth'
  import { Button } from 'svelma-fixed'
  import { RouterLink } from '../router'
  import { getAddressShortLabel } from '../lib/utils'
  import Loader from '../components/Loader.svelte'
</script>

<svelte:head>
	<title>Welcome - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4 has-text-centered">
    {#if $walletRestoring}
      <Loader borderless />
    {:else if !$activeWalletAddress}
      <h1 class="title is-1 my-5">
        Welcome to Tailor!
      </h1>

      <p class="my-5"><i class="big-icon fa fa-wallet"></i></p>

      <p class="is-size-4">Tailor will allow you to create decentralized, personalized NFT-based marketing campaigns.</p>
      <p class="is-size-4">In order to use Tailor, you need to connect your Ethereum wallet.</p>

      <p class="my-5"><Button size="is-large" type="is-primary" on:click={() => connectWallet()} loading={$walletConnecting}>Connect Wallet</Button></p>
    {:else if !$userInfo}
      <h1 class="title is-1 my-5">
        One more step...
      </h1>

      <p class="my-5"><i class="big-icon fa fa-key"></i></p>

      <p class="is-size-4">You need to verify ownership of the wallet <strong>{getAddressShortLabel($activeWalletAddress)}</strong> in order to sign in to Tailor.</p>
      <p class="is-size-4">This is done by signing a message in your wallet. There is no gas fee involved in this operation.</p>

      <p class="my-5"><Button size="is-large" type="is-primary" on:click={() => authenticate()} loading={$authenticateLoading}>Sign In</Button></p>

      <p><small><a href={undefined} on:click={expandAccountCenter}>Wrong wallet? Click here to switch or disconnect the wallet.</a></small></p>
    {:else}
      <!-- This should normally not be visible anyway because checkAuth() should have redirected -->
      <p>All set! <RouterLink to={{ name: 'dashboard' }}>Continue to dashboard</RouterLink></p>
    {/if}
  </div>
</div>
