<script>
  import DetailsTable from './DetailsTable.svelte'
  import { Button, Input } from 'svelma-fixed'
  import { escapeRegexp, getAddressShortLabel, isDoneOrPending } from '../lib/utils'
  import pluralize from 'pluralize'
  import { currentChain, TAILOR_CHAIN_ID } from '../stores/onboard'
  import { router } from '../router'
  import CampaignTags from './CampaignTags.svelte'
  import { userInfo } from '../stores/auth'

  export let search = ''
  export let campaigns = []

  let lastChain = $currentChain[TAILOR_CHAIN_ID]

  $: searchRegexp = new RegExp(`\\b${escapeRegexp(search.trim())}`, 'i')

  let type = lastChain.startsWith('0x') ? 'mainnet' : lastChain

  $: {
    const currentChainTailorId = $currentChain[TAILOR_CHAIN_ID]
    if (currentChainTailorId !== lastChain && lastChain === type) {
      type = currentChainTailorId
    }
    lastChain = currentChainTailorId
  }

  const filterCampaigns = campaigns => campaigns.filter(campaign => (
    (!search || campaign.name?.match(searchRegexp) || campaign.contractAddress?.match(searchRegexp)) &&
    (!$userInfo?.poweruser || ((type === 'archived' && campaign.archived) || (campaign.network === type && !campaign.archived)))
  ))
</script>

<DetailsTable>
  <div class="level">
    <div class="level-left">
      {#if $userInfo?.poweruser}
        <div class="tabs is-toggle is-toggle-rounded">
          <ul>
            <li class:is-active={type === 'mainnet'}><a href={undefined} on:click={() => (type = 'mainnet')}>Mainnet <div class="tag is-dark is-rounded ml-2">{campaigns.filter(campaign => campaign.network === 'mainnet' && !campaign.archived).length}</div></a></li>
            {#if !window.appVariables.disableGoerli}
              <li class:is-active={type === 'goerli'}><a href={undefined} on:click={() => (type = 'goerli')}>Testnet <div class="tag is-dark is-rounded ml-2">{campaigns.filter(campaign => campaign.network === 'goerli' && !campaign.archived).length}</div></a></li>
            {/if}
            <li class:is-active={type === 'archived'}><a href={undefined} on:click={() => (type = 'archived')}>Archived <div class="tag is-dark is-rounded ml-2">{campaigns.filter(campaign => campaign.archived).length}</div></a></li>
          </ul>
        </div>
      {:else}
        {pluralize('campaign', campaigns.length, true)}
      {/if}
    </div>
    <div class="level-right">
      <Input bind:value={search} placeholder="Search campaign" type="search" expanded />
    </div>
  </div>

  {#each filterCampaigns(campaigns) as campaign (campaign.id)}
    <div class="level hoverable" on:click={() => $router.push({ name: 'campaignDetails', params: { id: campaign.id } })} on:keypress>
      <div class="level-left">
        <div class="level-item is-justify-content-flex-start">
          <figure class="image is-64x64">
            <img style:height="100%" style:object-fit="contain" src={campaign.batches[0]?.metadata?.image ?? '/images/nft_placeholder.png'} alt="Campaign media" on:error={e => (e.target.src = '/images/nft_placeholder.png')} />
          </figure>
          <div class="ml-2">
            {campaign.name ?? 'Untitled campaign'}
            <CampaignTags {campaign} />
            <br />
            <small>Created at {new Date(campaign.createdAt).toLocaleString()}</small>
          </div>
          <br />
        </div>
      </div>
      <div class="level-right has-text-right-tablet">
        <div class="level-item is-justify-content-flex-start">
          <div>
            {pluralize('token', campaign.batches.filter(batch => isDoneOrPending(batch.minting, true) && !isDoneOrPending(batch.burning)).reduce((total, batch) => total + batch.count, 0), true)}
            <br />
            <small>
              {#if campaign.contractAddress}
                {getAddressShortLabel(campaign.contractAddress)}
              {:else}
                Contract not deployed
              {/if}
            </small>
          </div>
        </div>
      </div>
    </div>
  {:else}
    {#if search}
      <div>There are no campaigns matching "{search}".</div>
    {:else if !campaigns.length}
      <div class="has-text-centered">
        <h1 class="title is-1 my-5">Create your first campaign</h1>
        <p class="my-5"><i class="big-icon fa fa-rocket"></i></p>
        <p class="is-size-4">When creating a campaign you will be able to configure its image and metadata, pick an audience and mint your first NFTs in no time!</p>
        <p class="my-5"><Button size="is-large" type="is-primary" on:click={() => $router.push({ name: 'newCampaign', query: { network: (type === 'archived' || !$userInfo?.poweruser) ? undefined : type } })}>Let's Go</Button></p>
      </div>
    {:else}
      <div>No campaigns found.</div>
    {/if}
  {/each}
</DetailsTable>
