<script>
  import { Button, Dialog, Field, Input, Select, Toast } from 'svelma-fixed'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Loader from '../components/Loader.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { router } from '../router'
  import { apiCall } from '../lib/api'
  import { currentChain, TAILOR_CHAIN_ID } from '../stores/onboard'
  import { chains } from '../lib/enums'
  import uri from 'uri-tag'
  import { userInfo } from '../stores/auth'

  export let network = $currentChain[TAILOR_CHAIN_ID]
  export let duplicateCampaignId = null

  let sourceCampaignName = null

  const data = {
    name: '',
    contractName: '',
    network,
    duplicateCampaignId
  }

  async function loadDuplicateSource () {
    if (duplicateCampaignId) {
      const campaign = await apiCall('GET', uri`/api/campaigns/${duplicateCampaignId}`)
      sourceCampaignName = campaign.name
      data.name = campaign.name + ' (copy)'
      data.contractName = campaign.contractName
      data.network = campaign.network
    }
  }

  const loadingPromise = duplicateCampaignId ? loadDuplicateSource() : null

  const saving = createLoadingStore()

  async function save () {
    data.name = data.name.trim()
    data.contractName = data.contractName.trim()

    if (!$userInfo.poweruser) {
      data.network = 'mainnet'
    }

    if (!data.name) {
      return Dialog.alert({
        message: 'Please enter a name for the campaign.',
        type: 'is-danger'
      })
    }

    if (!data.contractName) {
      return Dialog.alert({
        message: 'Please enter a contract name for the campaign.',
        type: 'is-danger'
      })
    }

    await saving(async () => {
      const { id } = await apiCall('POST', '/api/campaigns', data)
      Toast.create({
        message: 'Campaign created.',
        type: 'is-success'
      })
      $router.push({ name: 'campaignAudience', params: { id }, query: { continueToMint: '1' } })
    })
  }
</script>

<svelte:head>
	<title>{duplicateCampaignId ? 'Duplicate' : 'New'} Campaign - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      {#if duplicateCampaignId}
        Duplicate Campaign {sourceCampaignName ? `"${sourceCampaignName}"` : ''}
      {:else}
        New Campaign
      {/if}
    </h4>

    {#await loadingPromise}
      <Loader borderless />
    {:then}
      <form on:submit|preventDefault={save}>
        <fieldset disabled={$saving}>
          <Field label="Internal Campaign Name" message="This name is only visible to you within Tailor and not published to the blockchain.">
            <Input bind:value={data.name} required />
          </Field>

          <Field label="Public Contract Name" message='This name is assigned to the contract and publicly visible in various places such as block explorers and wallets. It may also be displayed as "collection name". It cannot be changed once the contract has been deployed.'>
            <Input bind:value={data.contractName} required />
          </Field>

          {#if $userInfo?.poweruser}
            <Field label="Network">
              <Select bind:selected={data.network} expanded>
                {#each chains.entries() as [key, label]}
                  <option value={key}>{label}</option>
                {/each}
              </Select>
            </Field>
          {/if}

          <div class="field is-grouped">
            <div class="control">
              <Button type="is-primary" nativeType="submit" loading={$saving}>Create Campaign</Button>
            </div>
            <div class="control">
              <Button on:click={() => $router.push(duplicateCampaignId ? { name: 'campaignDetails', params: { id: duplicateCampaignId } } : { name: 'campaigns' })} disabled={$saving}>Cancel</Button>
            </div>
          </div>
        </fieldset>
      </form>
    {:catch error}
      <ErrorDisplay title="Failed to load campaign" {error} />
    {/await}
  </div>
</div>
