import { ethers } from 'ethers'
import { currentChainVal, getProvider, TAILOR_CHAIN_ID } from '../stores/onboard'

export function getBalanceKeeperContract () {
  const provider = getProvider(true)
  const signer = provider.getSigner()

  const tailorChainId = currentChainVal?.[TAILOR_CHAIN_ID]
  if (!tailorChainId) throw new Error(`Unknown network "${provider.network.chainId}"`)

  const address = window.appVariables.balanceKeeperContractAddresses[1][tailorChainId]
  if (!address) throw new Error(`Missing balance keeper contract address for tailorChainId ${provider.tailorChainId}`)

  return new ethers.Contract(address, window.appVariables.balanceKeeperContractAbi[1], signer)
}
