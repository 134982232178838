<script>
  import { Button, Icon, Dialog } from 'svelma-fixed'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Loader from '../components/Loader.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import { router, RouterLink } from '../router'
  import ActionExecutor from '../components/ActionExecutor.svelte'
  import { ensureNetwork, sendTx, activeWalletAddress } from '../stores/onboard'
  import pluralize from 'pluralize'
  import { isDoneOrPending } from '../lib/utils'
  import FeesWidget from '../components/FeesWidget.svelte'

  export let campaignId
  export let batchId

  const loading = createLoadingStore()
  let loadingPromise

  let campaign
  let batch

  let numberOfTokensToBurn

  $: backRoute = batchId ? { name: 'batchDetails', params: { campaignId, batchId } } : { name: 'campaignDetails', params: { id: campaignId } }

  async function load () {
    await (loadingPromise = loading(async () => {
      campaign = await apiCall('GET', uri`/api/campaigns/${campaignId}`)
      if (batchId) {
        batch = campaign.batches.find(b => b.id === batchId)
        if (!batch) throw new Error('Batch not found in campaign')

        numberOfTokensToBurn = batch.count
      } else {
        numberOfTokensToBurn = campaign.batches.filter(b => b.minting?.status === 'success' && !isDoneOrPending(b.burning)).reduce((sum, b) => sum + b.count, 0)
      }

      if (!batchId && campaign.multiBurning?.status !== 'pending' && campaign.batches.some(b => ['scheduled', 'processing', 'pending'].includes(b.minting?.status) || ['scheduled', 'processing', 'pending'].includes(b.burning?.status))) {
        Dialog.alert({
          title: 'Minting or burning in progress',
          message: 'There is a batch currently being minted or burned. Please wait until it is finished before burning this campaign.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'exclamation-triangle',
          confirmText: 'OK'
        })

        $router.push(backRoute)
      }
    }))
  }

  load()

  const burning = createLoadingStore()

  async function burn (setHash) {
    await burning(async () => {
      const { txDetails, batchIds } = await apiCall('POST', batchId ? uri`/api/campaigns/${campaignId}/batches/${batchId}/burn` : uri`/api/campaigns/${campaignId}/burn`, { sender: $activeWalletAddress })

      await ensureNetwork(campaign.network)
      const hash = await sendTx({ ...txDetails })
      await setHash(hash, { batchIds })
    })
  }
</script>

<svelte:head>
	<title>Burn {batchId ? 'Batch' : 'Campaign'} - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      {#if batchId}
        Burn Batch {batch?.metadata?.name ? `"${batch.metadata.name}"` : ''}
      {:else}
        Burn Campaign {campaign?.name ? `"${campaign.name}"` : ''}
      {/if}
    </h4>

    {#await loadingPromise}
      <Loader borderless />
    {:then}
      <ActionExecutor path={batchId ? uri`/api/campaigns/${campaignId}/batches/${batchId}/burning` : uri`/api/campaigns/${campaignId}/multiBurning`} network={campaign.network} text="Burning {batchId ? 'batch' : 'campaign'}..." let:setHash>
        {#if numberOfTokensToBurn <= 0}
          <ErrorDisplay type="is-warning" title="No tokens to burn!">
            <p>There are no minted tokens left to be burned.</p>
            <Button type="is-warning" outlined inverted on:click={() => $router.push(backRoute)}>Back</Button>
          </ErrorDisplay>
        {:else}
          <div class="has-text-centered">
            <h1 class="title is-1 my-5">Burn tokens</h1>
            <p class="my-5"><i class="big-icon fa fa-fire"></i></p>
            <p class="is-size-4">You are about to burn <strong>{numberOfTokensToBurn.toLocaleString()} {pluralize('token', numberOfTokensToBurn)}</strong>. This means the {pluralize('token', numberOfTokensToBurn)} will be removed from the {numberOfTokensToBurn === 1 ? 'wallet of its current owner' : 'wallets of their current owners'}.<br /><strong>This action cannot be undone!</strong></p>
            <FeesWidget --max-width="700px" {campaignId} operation="burn" numberOfTokens={Number(numberOfTokensToBurn) ?? 0} />
            <p class="my-5"><Button size="is-large" type="is-danger" on:click={() => burn(setHash)} loading={$burning}>Burn</Button></p>
            <p><RouterLink to={backRoute}><Icon icon="arrow-left" /> Back</RouterLink></p>
          </div>
        {/if}

        <div slot="pending">
          This operation will continue in the background.
          <RouterLink to={backRoute}><Icon icon="arrow-left" /> Back</RouterLink>
        </div>

        <div slot="success" class="has-text-centered">
          <h1 class="title is-1 my-5">Tokens Burned!</h1>
          <p class="my-5"><i class="big-icon fa fa-check-circle"></i></p>
          <p class="is-size-4">The {batchId ? 'batch' : 'campaign'} has been successfully burned. All tokens have been removed from their owners' wallets.</p>
          <p class="my-5"><Button size="is-large" on:click={() => $router.push(backRoute)}>Done</Button></p>
        </div>
      </ActionExecutor>
    {:catch error}
      <ErrorDisplay title="Failed to load campaign/batch info" {error} />
    {/await}
  </div>
</div>
