<script>
  import ErrorBox from '../components/ErrorBox.svelte'
  import Loader from '../components/Loader.svelte'
  import { Field, Input, Button, Dialog, Notification, Icon } from 'svelma-fixed'
  import Card from '../components/Card.svelte'
  import html from 'html-template-tag'
  import { ethers } from 'ethers'
  import { apiCall } from '../lib/api'
  import { formatError, getFileDataUri } from '../lib/utils'
  import { createLoadingStore } from '../stores/loading'
  import uri from 'uri-tag'
  import { onMount, onDestroy } from 'svelte'
  import { getTxStatusByHash } from '../lib/eth'
  import { getTailorContract } from '../lib/tailorContract'
  import JSONEditor from '../components/JSONEditor.svelte'

  let address
  let network

  const networks = {
    '0x1': 'mainnet',
    '0x5': 'goerli'
  }

  async function load () {
    if (window.ethereum?.isMetaMask) {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
      if (accounts.length) {
        address = accounts[0]
        const networkId = await window.ethereum.request({ method: 'eth_chainId' })
        network = networks[networkId] ?? networkId
      } else {
        throw new Error('No accounts found')
      }
    }
  }

  window.ethereum?.on('accountsChanged', async accounts => {
    if (accounts.length) {
      address = accounts[0]
    } else {
      address = undefined
      loadingPromise = undefined
    }

    updateContract()

    Notification.create({
      message: 'MetaMask account changed',
      type: 'is-info'
    })
  })

  window.ethereum?.on('chainChanged', async chainId => {
    network = networks[chainId] ?? chainId
    deploymentTxHash = null

    updateContract()

    Notification.create({
      message: 'MetaMask network changed',
      type: 'is-info'
    })
  })

  let loadingPromise = load()

  let testPassword = ''
  let contractAddress = ''

  let deploymentTxHash = null

  let contract = {
    loaded: false
  }

  $: (contractAddress, updateContract()) // eslint-disable-line no-unused-expressions

  const contractLoading = createLoadingStore()

  let deploymentTxCheckInterval
  onMount(() => {
    deploymentTxCheckInterval = setInterval(async () => {
      if (deploymentTxHash) {
        try {
          const txStatus = await getTxStatusByHash(deploymentTxHash)
          if (txStatus.status !== 'pending') {
            if (txStatus.status === 'dropped') {
              Notification.create({
                message: 'Deployment TX was dropped from the network!',
                type: 'is-danger'
              })
            } else if (txStatus.status === 'failed') {
              Notification.create({
                message: 'Deployment TX failed!',
                type: 'is-danger'
              })
            } else if (txStatus.status === 'success') {
              Notification.create({
                message: 'Deployment TX was confirmed!',
                type: 'is-success'
              })

              contractAddress = txStatus.receipt.contractAddress
            }

            deploymentTxHash = null
          }
        } catch (e) {
          console.error(e)
        }
      }
    }, 3000)
  })
  onDestroy(() => {
    if (deploymentTxCheckInterval) clearInterval(deploymentTxCheckInterval)
  })

  async function updateContract () {
    const invocationId = updateContract.currentInvocationId = crypto.randomUUID()

    if (!contractAddress.match(/^0x[a-fA-F0-9]{40}$/)) {
      contract = { loaded: false }
      return
    }

    contractLoading(async () => {
      try {
        let data
        try {
          data = await apiCall('GET', uri`/api/test/contract/${network}/${contractAddress}`, { _testPassword: testPassword })
        } finally {
          if (invocationId !== updateContract.currentInvocationId) return // eslint-disable-line no-unsafe-finally
        }
        contract = { loaded: true, ...data }
        console.log(`Contract ${contractAddress} loaded`, data)
      } catch (e) {
        console.error(`Failed to load contract ${contractAddress}`, e)
        contract = { loaded: false, error: e }
      }
    })
  }

  let actionValues = {
    deployContract: {
      loading: false,
      collectionName: '',
      image: null,
      nftName: '',
      nftDescription: '',
      targetUrl: '',
      additionalMetadata: {}
    },
    updateMetadata: {
      loading: false,
      image: null,
      nftName: '',
      nftDescription: '',
      targetUrl: '',
      additionalMetadata: {}
    },
    mintNfts: {
      loading: false,
      receivers: ''
    },
    burnNfts: {
      loading: false,
      firstId: '',
      count: ''
    },
    listOwnedNfts: {
      loading: false,
      ownerAddress: ''
    },
    transferOwnedNft: {
      loading: false,
      nftId: '',
      receiver: ''
    },
    burnOwnedNft: {
      loading: false,
      nftId: ''
    }
  }

  async function actionWrapper (values, fn) {
    values.loading = true
    actionValues = actionValues

    try {
      const txId = await fn()

      if (!txId) return

      console.log('TX ID:', txId)
      Dialog.alert({
        title: 'Success',
        message: html`
          <p>
            Your transaction has been submitted to the network.
          </p>
          <p>
            Transaction ID:<br />
            <a href="https://${network === 'mainnet' ? '' : (network + '.')}etherscan.io/tx/${txId}" target="_blank" rel="noreferrer" style="word-break: break-word;">${txId}</a>
          </p>
        `,
        type: 'is-success'
      })
    } catch (err) {
      console.error(err)

      const errorMessage = formatError(err)

      Dialog.alert({
        title: 'Error',
        message: html`
          <p>
            ${errorMessage}
          </p>
        `,
        type: 'is-danger'
      })

      throw err
    } finally {
      values.loading = false
      actionValues = actionValues
    }
  }

  async function deployContract (values) {
    await actionWrapper(values, async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const txParams = await apiCall('POST', '/api/test/deploy', {
        _testPassword: testPassword,
        collectionName: values.collectionName,
        image: await getFileDataUri(values.image),
        nftName: values.nftName,
        nftDescription: values.nftDescription,
        targetUrl: values.targetUrl,
        additionalMetadata: values.additionalMetadata
      })
      const tx = await signer.sendTransaction(txParams)
      console.log('deployContract TX', tx)

      contractAddress = ''
      deploymentTxHash = tx.hash

      return tx.hash
    }).catch(() => {})
  }

  async function updateMetadata (values) {
    await actionWrapper(values, async () => {
      await apiCall('POST', uri`/api/test/contract/${network}/${contractAddress}/updateMetadata`, {
        _testPassword: testPassword,
        image: await getFileDataUri(values.image),
        nftName: values.nftName,
        nftDescription: values.nftDescription,
        targetUrl: values.targetUrl,
        additionalMetadata: values.additionalMetadata
      })

      Dialog.alert({
        title: 'Success',
        message: html`
          <p>
            The metadata has been updated for future NFTs.
          </p>
        `,
        type: 'is-success'
      })
    }).catch(() => {})
  }

  async function mintNfts (values) {
    await actionWrapper(values, async () => {
      if (!contractAddress) throw new Error('Contract address is not set')

      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const txParams = await apiCall('POST', uri`/api/test/contract/${network}/${contractAddress}/mint`, {
        _testPassword: testPassword,
        sender: address,
        receivers: values.receivers.split('\n').map(s => s.trim()).filter(s => s)
      })
      const tx = await signer.sendTransaction(txParams)
      console.log('mintNfts TX', tx)

      return tx.hash
    }).catch(() => {})
  }

  async function burnNfts (values) {
    await actionWrapper(values, async () => {
      if (!contractAddress) throw new Error('Contract address is not set')

      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const txParams = await apiCall('POST', uri`/api/test/contract/${network}/${contractAddress}/burn`, {
        _testPassword: testPassword,
        sender: address,
        firstId: Number(values.firstId),
        count: Number(values.count)
      })
      const tx = await signer.sendTransaction(txParams)
      console.log('burnNfts TX', tx)

      return tx.hash
    }).catch(() => {})
  }

  async function listOwnedNfts (values) {
    await actionWrapper(values, async () => {
      if (!contractAddress) throw new Error('Contract address is not set')

      const contractInterface = getTailorContract(contractAddress)

      const ids = await contractInterface.getAllTokensByOwnerOffChain(values.ownerAddress)

      Dialog.alert({
        title: 'Success',
        message: html`
          <p>
            Address ${values.ownerAddress} owns the NFTs with the following IDs:
          </p>
          <p>
            <strong>${ids.map(Number).join(', ') || '(none)'}</strong>
          </p>
        `,
        type: 'is-success'
      })
    }).catch(() => {})
  }

  async function transferOwnedNft (values) {
    await actionWrapper(values, async () => {
      if (!contractAddress) throw new Error('Contract address is not set')

      const contractInterface = getTailorContract(contractAddress)

      const tx = await contractInterface.safeTransferFrom(address, values.receiver, Number(values.nftId), 1, '0x')

      console.log('transferOwnedNft TX', tx)

      return tx.hash
    }).catch(() => {})
  }

  async function burnOwnedNft (values) {
    await actionWrapper(values, async () => {
      if (!contractAddress) throw new Error('Contract address is not set')

      const contractInterface = getTailorContract(contractAddress)

      const tx = await contractInterface.burn(Number(values.nftId))

      console.log('burnOwnedNft TX', tx)

      return tx.hash
    }).catch(() => {})
  }
</script>

<svelte:head>
	<title>TX Test - Tailor</title>
</svelte:head>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 1rem;

    @include touch {
      grid-template-columns: 1fr;
    }
  }
</style>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      TX Test Page
    </h4>
    {#if window.ethereum?.isMetaMask}
      {#if loadingPromise}
        {#await loadingPromise}
          <Loader />
        {:then}
          <div class="columns">
            <div class="column is-6">
              <p class="mb-4">Address: <strong><a href="https://{network === 'mainnet' ? '' : (network + '.')}etherscan.io/address/{address}" target="_blank" rel="noreferrer" style="word-break: break-word;">{address}</a></strong></p>
              <p class="mb-4">Network: <strong>{network}</strong></p>
            </div>
            <div class="column is-3">
              <Field label="Test password">
                <Input type="password" bind:value={testPassword} />
              </Field>
            </div>
            <div class="column is-3">
              <Field label="Contract address">
                <Input type="text" bind:value={contractAddress} disabled={!!deploymentTxHash} />
              </Field>
            </div>
          </div>
          <hr />
          {#if deploymentTxHash}
            <p class="mb-4">
              <Icon icon="spinner" customClass="fa-pulse" /> Waiting for contract deployment TX to be confirmed... (<a href="https://${network === 'mainnet' ? '' : (network + '.')}etherscan.io/tx/${deploymentTxHash}" target="_blank" rel="noreferrer" style="word-break: break-word;">{deploymentTxHash}</a>)
            </p>
          {:else if $contractLoading}
            <p class="mb-4">
              <Icon icon="spinner" customClass="fa-pulse" /> Updating contract info...
            </p>
          {:else if contract.loaded}
            <div class="columns">
              <div class="column is-6">
                <p class="mb-4">Contract address: <strong><a href="https://{network === 'mainnet' ? '' : (network + '.')}etherscan.io/address/{contract.address}" target="_blank" rel="noreferrer" style="word-break: break-word;">{contract.address}</a></strong></p>
                <p class="mb-4">Tailor contract version: <strong>{contract.version}{contract.unsupported ? '(unsupported!)' : ''}</strong></p>
                <p class="mb-4">Collection name: <strong>{contract.collectionName}</strong></p>
                <p class="mb-4">Metadata URI: <strong><span style="word-break: break-word;">{contract.metadataUri}</span></strong></p>
                <p class="mb-4">Max. supply: <strong>{contract.maxSupply}</strong></p>
                <p class="mb-4">Your privilege level: <strong>{contract.admins?.map(x => x.toLowerCase()).includes(address.toLowerCase()) ? 'Admin' : contract.managers?.map(x => x.toLowerCase()).includes(address.toLowerCase()) ? 'Manager' : '(none)'}</strong></p>
              </div>
              <div class="column is-6">
                <p class="mb-4">Mint fee: <strong>{ethers.utils.formatEther(contract.mintFee ?? 0)} ETH</strong></p>
                <p class="mb-4">Burn fee: <strong>{ethers.utils.formatEther(contract.burnFee ?? 0)} ETH</strong></p>
                <p class="mb-4">Fee recipient: <strong><a href="https://{network === 'mainnet' ? '' : (network + '.')}etherscan.io/address/{contract.feeRecipient}" target="_blank" rel="noreferrer" style="word-break: break-word;">{contract.feeRecipient}</a></strong></p>
                <p class="mb-4">
                  <span>Admins: </span>
                  {#each contract.admins ?? [] as admin}
                    <strong><a href="https://{network === 'mainnet' ? '' : (network + '.')}etherscan.io/address/{admin}" target="_blank" rel="noreferrer" style="word-break: break-word;">{admin}</a></strong>{admin !== contract.admins.at(-1) ? ',' : ''}
                  {:else}
                    <strong>(none)</strong>
                  {/each}
                </p>
                <p class="mb-4">
                  <span>Managers: </span>
                  {#each contract.managers ?? [] as manager}
                    <strong><a href="https://{network === 'mainnet' ? '' : (network + '.')}etherscan.io/address/{manager}" target="_blank" rel="noreferrer" style="word-break: break-word;">{manager}</a></strong>{manager !== contract.managers.at(-1) ? ',' : ''}
                  {:else}
                    <strong>(none)</strong>
                  {/each}
                </p>
              </div>
            </div>
          {:else if contract.error}
            <p class="mb-4 has-text-danger">
              <Icon icon="warning" /> Error loading contract info: <strong>{formatError(contract.error)}</strong>
            </p>
          {:else}
            <p class="mb-4">
              No contract loaded.
            </p>
          {/if}

          {#if !$contractLoading && (contract.loaded || contract.error)}
            <p class="mb-4">
              <Button on:click={() => updateContract()} iconLeft="refresh">Update contract info</Button>
            </p>
          {/if}

          <div class="two-columns">
            <Card title="Deploy Tailor Contract" icon="rocket">
              <form on:submit|preventDefault={() => deployContract(actionValues.deployContract)}>
                <fieldset disabled={actionValues.deployContract.loading}>
                  <Field label="Collection name">
                    <Input required bind:value={actionValues.deployContract.collectionName} />
                  </Field>
                  <Field label="Image">
                    <Input type="file" required on:change={e => { actionValues.deployContract.image = e.target.files[0] }} />
                  </Field>
                  <Field label="NFT name">
                    <Input required bind:value={actionValues.deployContract.nftName} />
                  </Field>
                  <Field label="NFT description">
                    <Input type="textarea" required bind:value={actionValues.deployContract.nftDescription} />
                  </Field>
                  <Field label="Target URL">
                    <Input required bind:value={actionValues.deployContract.targetUrl} />
                  </Field>
                  <Field label="Additional metadata">
                    <div class="field-body">
                      <div class="field">
                        <JSONEditor bind:document={actionValues.deployContract.additionalMetadata} />
                      </div>
                    </div>
                  </Field>
                  <Button type="is-primary" nativeType="submit" loading={actionValues.deployContract.loading}>Submit</Button>
                </fieldset>
              </form>
            </Card>

            <Card title="Update NFT Metadata (for future mints)" icon="file-upload">
              <form on:submit|preventDefault={() => updateMetadata(actionValues.updateMetadata)}>
                <fieldset disabled={actionValues.updateMetadata.loading}>
                  <Field label="Image">
                    <Input type="file" required on:change={e => { actionValues.updateMetadata.image = e.target.files[0] }} />
                  </Field>
                  <Field label="NFT name">
                    <Input required bind:value={actionValues.updateMetadata.nftName} />
                  </Field>
                  <Field label="NFT description">
                    <Input type="textarea" required bind:value={actionValues.updateMetadata.nftDescription} />
                  </Field>
                  <Field label="Target URL">
                    <Input required bind:value={actionValues.updateMetadata.targetUrl} />
                  </Field>
                  <Field label="Additional metadata">
                    <div class="field-body">
                      <div class="field">
                        <JSONEditor bind:document={actionValues.updateMetadata.additionalMetadata} />
                      </div>
                    </div>
                  </Field>
                  <Button type="is-primary" nativeType="submit" loading={actionValues.updateMetadata.loading}>Submit</Button>
                </fieldset>
              </form>
            </Card>

            <Card title="Mint NFTs (privileged)" icon="coins">
              <form on:submit|preventDefault={() => mintNfts(actionValues.mintNfts)}>
                <fieldset disabled={actionValues.mintNfts.loading}>
                  <Field label="Receiver addresses">
                    <Input type="textarea" required bind:value={actionValues.mintNfts.receivers} />
                  </Field>
                  <Button type="is-primary" nativeType="submit" loading={actionValues.mintNfts.loading}>Submit</Button>
                  <p class="mt-2"><strong>Warning:</strong> Only execute this operation when no other contract action is pending, otherwise there is a risk to double-mint NFTs!</p>
                </fieldset>
              </form>
            </Card>

            <Card title="Burn NFTs (privileged)" icon="fire">
              <form on:submit|preventDefault={() => burnNfts(actionValues.burnNfts)}>
                <fieldset disabled={actionValues.burnNfts.loading}>
                  <Field label="First ID">
                    <Input required bind:value={actionValues.burnNfts.firstId} />
                  </Field>
                  <Field label="Count">
                    <Input required bind:value={actionValues.burnNfts.count} />
                  </Field>
                  <Button type="is-primary" nativeType="submit" loading={actionValues.burnNfts.loading}>Submit</Button>
                </fieldset>
              </form>
            </Card>

            <Card title="List Owned NFTs" icon="file-image">
              <form on:submit|preventDefault={() => listOwnedNfts(actionValues.listOwnedNfts)}>
                <fieldset disabled={actionValues.listOwnedNfts.loading}>
                  <Field label="Owner address">
                    <div class="field-body">
                      <div class="field has-addons">
                        <Input expanded required bind:value={actionValues.listOwnedNfts.ownerAddress} />
                        <div class="control">
                          <Button on:click={() => (actionValues.listOwnedNfts.ownerAddress = address)}>Self</Button>
                        </div>
                      </div>
                    </div>
                  </Field>
                  <Button type="is-primary" nativeType="submit" loading={actionValues.listOwnedNfts.loading}>Check</Button>
                </fieldset>
              </form>
            </Card>

            <Card title="Transfer Owned NFT" icon="share">
              <form on:submit|preventDefault={() => transferOwnedNft(actionValues.transferOwnedNft)}>
                <fieldset disabled={actionValues.transferOwnedNft.loading}>
                  <Field label="NFT ID">
                    <Input required bind:value={actionValues.transferOwnedNft.nftId} />
                  </Field>
                  <Field label="Receiver address">
                    <Input required bind:value={actionValues.transferOwnedNft.receiver} />
                  </Field>
                  <Button type="is-primary" nativeType="submit" loading={actionValues.transferOwnedNft.loading}>Submit</Button>
                </fieldset>
              </form>
            </Card>

            <Card title="Burn Owned NFT" icon="fire">
              <form on:submit|preventDefault={() => burnOwnedNft(actionValues.burnOwnedNft)}>
                <fieldset disabled={actionValues.burnOwnedNft.loading}>
                  <Field label="NFT ID">
                    <Input required bind:value={actionValues.burnOwnedNft.nftId} />
                  </Field>
                  <Button type="is-primary" nativeType="submit" loading={actionValues.burnOwnedNft.loading}>Submit</Button>
                </fieldset>
              </form>
            </Card>
          </div>
        {:catch error}
          <ErrorBox>
            Connection to MetaMask failed - {error}.
            <a href={undefined} on:click={() => (loadingPromise = load())}>Retry</a>
          </ErrorBox>
        {/await}
      {:else}
        Not connected to MetaMask.
        <a href={undefined} on:click={() => (loadingPromise = load())}>Connect</a>
      {/if}
    {:else}
      <ErrorBox>
        You need to install MetaMask to use this page.
      </ErrorBox>
    {/if}
  </div>
</div>
