<script>
  import { Button, Collapse, Dialog, Field, Icon, Input, Notification, Toast } from 'svelma-fixed'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Loader from '../components/Loader.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { router } from '../router'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import JSONEditor from '../components/JSONEditor.svelte'
  import ImageDisplay from '../components/ImageDisplay.svelte'
  import { getFileDataUri } from '../lib/utils'
  import { userInfo } from '../stores/auth'

  export let campaignId = null
  export let batchId = null
  export let duplicateBatchId = null

  let sourceBatchName = null
  let campaign = null
  let additionalMetadataOpen = false

  let fileInput

  let data = {
    image: '',
    name: '',
    description: '',
    external_url: '',
    extra: {}
  }

  async function load () {
    campaign = await apiCall('GET', uri`/api/campaigns/${campaignId}`)

    let batch
    if (duplicateBatchId) {
      batch = campaign.batches.find(b => b.id === duplicateBatchId)
      sourceBatchName = batch.metadata?.name
    } else if (batchId) {
      batch = campaign.batches.find(b => b.id === batchId)
    }

    if (batch) {
      const { image, name, description, external_url: externalUrl, ...extra } = batch.metadata ?? {}
      Object.assign(data, { image, name, description, external_url: externalUrl, extra })
      data = data // trigger reactivity
    }

    additionalMetadataOpen = Object.keys(data.extra).length > 0
  }

  const loadingPromise = load()

  const saving = createLoadingStore()
  const uploadingImage = createLoadingStore()

  async function save () {
    data.name = data.name?.trim()
    data.description = data.description?.trim()
    data.external_url = data.external_url?.trim()

    if (!data.image) {
      return Dialog.alert({
        message: 'Please set an image for the batch.',
        type: 'is-danger'
      })
    }

    if (!data.name) {
      return Dialog.alert({
        message: 'Please enter a name for the batch.',
        type: 'is-danger'
      })
    }

    if (!data.description) {
      return Dialog.alert({
        message: 'Please enter a description for the batch.',
        type: 'is-danger'
      })
    }

    if (!data.external_url) {
      return Dialog.alert({
        message: 'Please enter a target URL for the batch.',
        type: 'is-danger'
      })
    }

    await saving(async () => {
      const { image, name, description, external_url: externalUrl, extra } = data
      const preparedData = { metadata: { image, name, description, external_url: externalUrl, ...extra } }

      if (batchId) {
        await apiCall('PATCH', uri`/api/campaigns/${campaignId}/batches/${batchId}`, preparedData)
        Toast.create({
          message: 'Batch updated.',
          type: 'is-success'
        })
        $router.push({ name: 'batchDetails', params: { campaignId, batchId } })
      } else {
        const { id } = await apiCall('POST', uri`/api/campaigns/${campaignId}/batches`, preparedData)
        Toast.create({
          message: 'Batch created.',
          type: 'is-success'
        })
        $router.push({ name: 'mintBatch', params: { campaignId, batchId: id } })
      }
    })
  }

  function selectImage () {
    fileInput.value = ''
    fileInput.click()
  }

  async function uploadImage () {
    const file = fileInput.files[0]
    if (!file) return

    await uploadingImage(async () => {
      const { url } = await apiCall('POST', uri`/api/campaigns/${campaignId}/images`, {
        image: await getFileDataUri(file)
      })
      data.image = url
    })
  }

  $: unsubscribeLink = campaign ? `\n\n- Unsubscribe: ${window.location.origin + $router.routeURL({ name: 'unsubscribe', params: { id: campaign.owner } })}` : ''

  function addUnsubscribeLink () {
    if (!data.description?.endsWith(unsubscribeLink)) {
      if (!data.description) data.description = ''
      data.description += unsubscribeLink
    }
  }
</script>

<svelte:head>
	<title>{batchId ? 'Edit' : duplicateBatchId ? 'Duplicate' : 'New'} Batch - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      {#if batchId}
        Edit Batch {data.name ? `"${data.name}"` : ''}
      {:else if duplicateBatchId}
        Duplicate Batch {sourceBatchName ? `"${sourceBatchName}"` : ''}
      {:else}
        New Batch
      {/if}
    </h4>

    {#await loadingPromise}
      <Loader borderless />
    {:then}
      <form on:submit|preventDefault={save}>
        <fieldset disabled={$saving}>
          <div class="columns">
            <div class="column is-half">
              <Field label="NFT Name" message="This name is publicly visible and shown as title of the NFT.">
                <Input bind:value={data.name} required />
              </Field>

              <Field label="NFT Description" message="This text is displayed alongside the NFT. Note that the supported formatting options can vary by client. It is recommended not to use &lt; or &gt; characters. Line breaks may be ignored by certain clients.">
                <Input type="textarea" bind:value={data.description} required />
              </Field>

              <Field>
                <Button size="is-small" on:click={addUnsubscribeLink} disabled={data.description?.endsWith(unsubscribeLink)}>Add Unsubscribe Link</Button>
              </Field>

              <Field label="Target URL" message="This link is displayed alongside the NFT, sometimes as button. This should be the website to which you would like to direct the user. Placeholders {'{campaign}'} and {'{id}'} are allowed.">
                <Input type="url" bind:value={data.external_url} required />
              </Field>
            </div>

            <div class="column is-half">
              <div class="block mb-2">
                <strong>NFT Image</strong>
              </div>
              <div class="block">
                <Button type="is-primary" iconLeft="cloud-upload" on:click={selectImage} loading={$uploadingImage}>Upload Image</Button>
                <input type="file" accept="image/*" hidden bind:this={fileInput} on:change={uploadImage} />
              </div>
              {#if data.image}
                <div class="block">
                  <ImageDisplay src={data.image} alt="NFT Media" />
                </div>
              {/if}
            </div>
          </div>

          {#if $userInfo.poweruser}
            <div class="mb-4">
              <Collapse bind:open={additionalMetadataOpen}>
                <a slot="trigger" class="is-size-7 has-text-grey" href={undefined}>
                  {#if additionalMetadataOpen}
                    <Icon icon="minus" /> Hide advanced settings
                  {:else}
                    <Icon icon="plus" /> Show advanced settings
                  {/if}
                </a>

                <Field label="Additional metadata" message="This JSON object is read by NFT wallets and can be used to store additional information about the NFT. Different clients may support different properties.">
                  <div class="field-body">
                    <div class="field">
                      <JSONEditor bind:document={data.extra} />
                    </div>
                  </div>
                </Field>
              </Collapse>
            </div>
          {/if}

          {#if !batchId}
            <Notification showClose={false}><Icon icon="info-circle" /> The number of tokens to mint in this batch will be set in a following step.</Notification>
          {/if}

          <div class="field is-grouped">
            <div class="control">
              <Button type="is-primary" nativeType="submit" loading={$saving} disabled={$uploadingImage}>{batchId ? 'Save' : 'Create Batch'}</Button>
            </div>
            <div class="control">
              <Button on:click={() => $router.push((batchId ?? duplicateBatchId) ? { name: 'batchDetails', params: { campaignId, batchId: batchId ?? duplicateBatchId } } : { name: 'campaignDetails', params: { id: campaignId } })} disabled={$saving || $uploadingImage}>Cancel</Button>
            </div>
          </div>
        </fieldset>
      </form>
    {:catch error}
      <ErrorDisplay title="Failed to load campaign" {error} />
    {/await}
  </div>
</div>
