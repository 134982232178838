<script>
  import DetailsTable from './DetailsTable.svelte'
  import { Input } from 'svelma-fixed'
  import pluralize from 'pluralize'
  import { router } from '../router'
  import BatchTags from './BatchTags.svelte'
  import { escapeRegexp, isDoneOrPending } from '../lib/utils'

  export let search = ''
  export let campaign

  $: searchRegexp = new RegExp(`\\b${escapeRegexp(search.trim())}`, 'i')

  const filterBatches = batches => batches.filter(batch => (
    (!search || batch.metadata?.name?.match(searchRegexp) || batch.metadata?.description?.match(searchRegexp))
  ))
</script>

<style lang="scss">
  .burned {
    text-decoration: line-through;
  }
</style>

<DetailsTable>
  <div class="level">
    <div class="level-left">
      {pluralize('batch', campaign.batches.length, true)}, {pluralize('token', campaign.batches.filter(batch => isDoneOrPending(batch.minting, true) && !isDoneOrPending(batch.burning)).reduce((total, batch) => total + batch.count, 0), true)}
    </div>
    <div class="level-right">
      <Input bind:value={search} placeholder="Search batch" type="search" expanded />
    </div>
  </div>

  {#each filterBatches(campaign.batches) as batch (batch.id)}
    <div class="level hoverable" on:click={() => $router.push({ name: 'batchDetails', params: { campaignId: campaign.id, batchId: batch.id } })} on:keypress>
      <div class="level-left">
        <div class="level-item is-justify-content-flex-start">
          <figure class="image is-64x64">
            <img style:height="100%" style:object-fit="contain" src={batch.metadata?.image ?? '/images/nft_placeholder.png'} alt="Batch media" on:error={e => (e.target.src = '/images/nft_placeholder.png')} />
          </figure>
          <div class="ml-2">
            <span class:burned={isDoneOrPending(batch.burning)}>{batch.metadata?.name ?? 'Untitled batch'}</span>
            <BatchTags {batch} />
            <br />
            <small>Created at {new Date(batch.createdAt).toLocaleString()}</small>
          </div>
          <br />
        </div>
      </div>
      <div class="level-right has-text-right-tablet">
        <div class="level-item is-justify-content-flex-start">
          <div>
            <span class:burned={isDoneOrPending(batch.burning)}>{batch.start == null ? 'Not minted' : pluralize('token', batch.count, true)}</span>
            <br />
            <small>
              {#if batch.start != null && batch.end != null}
                {#if batch.count === 1}
                  #{batch.start}
                {:else}
                  #{batch.start} &ndash; #{batch.end - 1}
                {/if}
              {/if}
            </small>
          </div>
        </div>
      </div>
    </div>
  {:else}
    {#if search}
      <div>There are no batches matching "{search}".</div>
    {:else}
      <div>No batches found.</div>
    {/if}
  {/each}
</DetailsTable>
