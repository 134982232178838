<script>
  import { formatCurrency } from '../lib/utils'

  export let fees
  export let maxFeePerGas
  export let gas = 0
  export let ethValue = 0
  export let quantity = 1

  export let total = false
  export let strikeThrough = false

  $: singleEthValue = (ethValue + ((maxFeePerGas ?? fees.gasPriceInfo.maxFeePerGas) * gas) / 1e9)
  $: calculatedEthValue = quantity * singleEthValue
  $: calculatedUsdValue = calculatedEthValue * fees.ethPrice
</script>

<style lang="scss">
  .row {
    border-bottom: 1px solid $border;

    &.total {
      border-bottom: none;
      font-weight: bold;
    }

    &.strikeThrough .value {
      text-decoration: line-through;
    }
  }
</style>

<div class="row columns {$$props.class ?? ''}" class:total class:strikeThrough>
  <div class="column is-6">
    <slot {singleEthValue} singleEthValueFormatted="{singleEthValue.toFixed(6)}&nbsp;{fees.tokenName}">
      {#if total}
        Estimated total fees
      {/if}
    </slot>
  </div>
  <div class="column is-4 value has-text-right">
    {calculatedEthValue.toFixed(6)}&nbsp;{fees.tokenName}
  </div>
  <div class="column is-2 value has-text-right">
    {formatCurrency(calculatedUsdValue, 'USD', 2)}
  </div>
</div>
