'use strict'

import { checkAuth } from '../router'
import { updateUserInfo, initUserUpdate } from '../stores/auth'
import { apiCall } from './api'

// This is loaded once on startup and contains static app data as well the currently logged-in user - it won't change without reload
export const appInfo = window.appInfo = { initialized: false }

export async function initAppInfo () {
  const [newAppInfo] = await Promise.all([
    apiCall('GET', '/api/info'),
    updateUserInfo() // no return value
  ])
  initUserUpdate()
  for (const k in appInfo) delete appInfo[k]
  Object.assign(appInfo, newAppInfo, { initialized: true })
  checkAuth()
}
