<script>
  export let title
  export let icon
  export let iconPack = 'fas'
</script>

<style lang="scss">
  span.card-header-icon {
    cursor: inherit;
  }
</style>

<div class="card">
  <header class="card-header">
    {#if title || $$slots.title}
      <p class="card-header-title">
        <slot name="title">{title}</slot>
      </p>
    {/if}
    {#if icon}
      <span class="card-header-icon" aria-label="{title ?? ''}">
        <span class="icon">
          <i class="{iconPack} fa-{icon}" aria-hidden="true"></i>
        </span>
      </span>
    {/if}
  </header>
  <div class="card-content">
    <slot />
  </div>
  {#if $$slots.footer}
    <footer class="card-footer">
      <slot name="footer" />
    </footer>
  {/if}
</div>
