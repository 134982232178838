<script>
  import { formatCurrency, onlyCustom, parseCurrency } from '../../lib/utils'
  import ModalDialog from '../ModalDialog.svelte'
  import { Button, Field, Input } from 'svelma-fixed'
  import { apiCall } from '../../lib/api'
  import { createLoadingStore } from '../../stores/loading'
  import { createEventDispatcher, tick } from 'svelte'
  import ErrorDisplay from '../ErrorDisplay.svelte'
  import Loader from '../Loader.svelte'
  import { ensureNetwork } from '../../stores/onboard'
  import { getBalanceKeeperContract } from '../../lib/balanceKeeper'
  import { ethers } from 'ethers'
  import dialogs from '../../stores/dialogs'
  import TxWaitDialog from './TxWaitDialog.svelte'

  export let network
  export let mode

  export let initialAmount

  $: title = { deposit: 'Deposit Scheduled Task Funds', withdraw: 'Withdraw Scheduled Task Funds' }[mode] ?? 'Balance Keeper'
  $: actionName = { deposit: 'Deposit', withdraw: 'Withdraw' }[mode] ?? 'Execute'

  export let data = {
    amount: undefined,
    all: false
  }

  const editValues = {
    units: initialAmount != null ? formatCurrency(initialAmount, undefined, -10) : '',
    quoteValue: ''
  }

  const dispatch = createEventDispatcher()

  const loading = createLoadingStore()
  const submitLoading = createLoadingStore()

  let unitsFieldColumn

  let loadingPromise
  let balanceInfo

  async function load () {
    await (loadingPromise = loading(async () => {
      const balances = await apiCall('GET', '/api/balances', { network })
      balanceInfo = balances[network]

      tick().then(() => {
        onUnitsChange(true)
        setTimeout(() => unitsFieldColumn.querySelector('input')?.focus(), 200)
      })
    }))
  }

  load()

  function setAll () {
    data.amount = { deposit: balanceInfo.wallet.balance, withdraw: balanceInfo.balanceKeeper.balance }[mode] ?? 0
    data.all = true

    formatEditValue('units', data.amount, true)
    onUnitsChange(true)
  }

  function isValid (data) {
    if (!balanceInfo) return false
    const max = { deposit: balanceInfo.wallet.balance, withdraw: balanceInfo.balanceKeeper.balance }[mode] ?? 0
    return (data.amount > 0 && (data.amount <= max || data.all))
  }

  function parseEditValue (key, value = editValues[key]) {
    return ({
      units: () => Math.max(0, parseCurrency(value)),
      quoteValue: () => Math.max(0, parseCurrency(value, 'USD'))
    })[key]()
  }

  function reformatEditValue (key) {
    formatEditValue(key, parseEditValue(key), true)
  }

  function formatEditValue (key, value, force = false) {
    const formatted = ({
      units: () => formatCurrency(value, undefined, -10),
      quoteValue: () => formatCurrency(value, 'USD')
    })[key]()

    if (force || editValues[key] === undefined || parseEditValue(key, formatted) !== parseEditValue(key)) {
      editValues[key] = formatted
      tick().then(() => { editValues[key] = formatted }) // Weird bug: Input value doesn't update if it's not set twice in some conditions? (Test with changing units...)
    }
  }

  function onUnitsChange (force = false) {
    if (!force) data.all = false
    if (!force || !data.all) data.amount = parseEditValue('units')

    formatEditValue('quoteValue', data.amount * balanceInfo.ethPrice, force)
  }

  function onQuoteValueChange (force = false) {
    data.all = false
    data.amount = parseEditValue('quoteValue') / balanceInfo.ethPrice

    formatEditValue('units', data.amount, force)
  }

  async function executeContractCall () {
    const contractInterface = await getBalanceKeeperContract(network)

    if (mode === 'deposit') {
      return await contractInterface.deposit({ value: ethers.utils.parseEther(data.amount.toString()) })
    } else if (mode === 'withdraw') {
      return await contractInterface.withdraw(data.all ? await contractInterface.balanceOf(await contractInterface.signer.getAddress()) : ethers.utils.parseEther(data.amount.toString()))
    } else {
      throw new Error('Invalid mode')
    }
  }

  async function submit () {
    await submitLoading(async () => {
      await ensureNetwork(network)
      console.log('Balance keeper contract interaction', mode, data)
      const tx = await executeContractCall()
      console.log('Balance keeper TX result', tx)

      dispatch('close', dialogs.open(TxWaitDialog, { tx, network }))
    })
  }
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .label {
    font-size: 1.25rem;
    line-height: 1.75;
    margin-top: 1rem;
  }

  .edit-field-label {
    font-size: 0.8rem;
    color: #c7c7c7;
    display: block;
  }

  :global(.field) + .subtext, :global(.control) + .subtext {
    margin-top: 0.5em;
  }

  .subtext {
    display: block;
    font-size: 0.8rem;
  }

  .column :global(.field) {
    margin-bottom: 0;
  }

  .column :global(.field > .control:first-child) {
    flex: 1;
  }
</style>

<ModalDialog large --min-width="700px" on:close {title} closeable>
  {#await loadingPromise}
    <Loader borderless />
  {:then}
    <fieldset disabled={$submitLoading}>
      <div class="level is-mobile mb-1">
        <div class="level-left">
          <h2 class="label">{actionName} Amount:</h2>
        </div>
        <div class="level-right">
          {#if mode === 'withdraw'}
            <Button {...data.all ? { disabled: true, type: 'is-dark' } : { disabled: (balanceInfo.balanceKeeper.balanceWei ?? 0) <= 0, outlined: true } } on:click={setAll}>All</Button>
          {/if}
        </div>
      </div>

      <div class="columns">
        <div class="column is-6" bind:this={unitsFieldColumn}>
          <span class="edit-field-label">Units</span>
          <Field>
            <Input bind:value={editValues.units} on:input={onlyCustom(() => onUnitsChange())} on:change={() => reformatEditValue('units')} />
            <p class="control">
              <Button type="is-static">{balanceInfo.tokenName ?? '-'}</Button>
            </p>
          </Field>

          {#if mode === 'deposit'}
            <span class="subtext" class:has-text-danger={data.amount > balanceInfo.wallet.balance}>Wallet Balance: {formatCurrency(balanceInfo.wallet.balance ?? 0, undefined, -6, '-', true)} {balanceInfo.tokenName}</span>
            <span class="subtext">Scheduled Task Funds Balance: {formatCurrency(balanceInfo.balanceKeeper.balance ?? 0, undefined, -6, '-', true)} {balanceInfo.tokenName}</span>
          {:else if mode === 'withdraw'}
            <span class="subtext" class:has-text-danger={data.amount > balanceInfo.balanceKeeper.balance}>Scheduled Task Funds Balance: {formatCurrency(balanceInfo.balanceKeeper.balance ?? 0, undefined, -6, '-', true)} {balanceInfo.tokenName}</span>
            <span class="subtext">Wallet Balance: {formatCurrency(balanceInfo.wallet.balance ?? 0, undefined, -6, '-', true)} {balanceInfo.tokenName}</span>
          {/if}
        </div>

        <div class="column is-6">
          <span class="edit-field-label">USD Value</span>
          <Input bind:value={editValues.quoteValue} on:input={onlyCustom(() => onQuoteValueChange())} on:change={() => reformatEditValue('quoteValue')} disabled={!balanceInfo.ethPrice} />

          {#if mode === 'deposit'}
            <span class="subtext" class:has-text-danger={data.amount > balanceInfo.wallet.balance}>Wallet Balance: {formatCurrency(balanceInfo.wallet.balanceUsd ?? 0, 'USD', 2, '-', true)}</span>
            <span class="subtext">Scheduled Task Funds Balance: {formatCurrency(balanceInfo.balanceKeeper.balanceUsd ?? 0, 'USD', 2, '-', true)}</span>
          {:else if mode === 'withdraw'}
            <span class="subtext" class:has-text-danger={data.amount > balanceInfo.balanceKeeper.balance}>Scheduled Task Funds Balance: {formatCurrency(balanceInfo.balanceKeeper.balanceUsd ?? 0, 'USD', 2, '-', true)}</span>
            <span class="subtext">Wallet Balance: {formatCurrency(balanceInfo.wallet.balanceUsd ?? 0, 'USD', 2, '-', true)}</span>
          {/if}
        </div>
      </div>
    </fieldset>
  {:catch error}
    <ErrorDisplay title="Failed to load balance info" {error} />
  {/await}

  <svelte:fragment slot="footer">
    {#key balanceInfo}
      <Button type="is-primary" on:click={submit} disabled={!isValid(data) || $loading} loading={$submitLoading}>
        {#if data.amount > 0}
          {actionName} {data.all ? 'All' : `${formatCurrency(data.amount, undefined, -6, '-', true)} ${balanceInfo.tokenName}`}
        {:else}
          {actionName}
        {/if}
      </Button>
      <Button on:click={() => dispatch('close')}>Cancel</Button>
    {/key}
  </svelte:fragment>
</ModalDialog>
