<script>
  import JSONEditor from 'jsoneditor' // Unfortunately, svelte-jsoneditor messes up Rollup big time
  import 'jsoneditor/dist/jsoneditor.css'

  export let document = {}
  let prevJson = JSON.stringify(document)
  let editor

  function set (editor, data) {
    editor.set(data)
    editor.expand({ path: '' })
  }

  function jsonEditor (element) {
    editor = new JSONEditor(element, {
      mode: 'tree',
      modes: ['code', 'tree'],
      onChangeJSON: (data) => {
        document = data
        prevJson = JSON.stringify(data)
      }
    })

    set(editor, document)

    return () => {
      editor.destroy()
      editor = null
    }
  }

  $: if (editor) {
    const json = JSON.stringify(document)
    if (json !== prevJson) {
      set(editor, document)
    }
  }
</script>

<style>
  div :global(.jsoneditor) {
    min-height: var(--json-editor-height, 300px);
  }
</style>

<div {...$$restProps} use:jsonEditor />
