<script>
  import Loader from '../components/Loader.svelte'
  import { Field, Input, Button, Toast, Switch } from 'svelma-fixed'
  import { apiCall } from '../lib/api'
  import { createLoadingStore } from '../stores/loading'
  import uri from 'uri-tag'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import { router } from '../router'
  import CampaignTags from '../components/CampaignTags.svelte'

  export let campaignId

  const loading = createLoadingStore()
  let loadingPromise

  let campaign
  let redeemConfig

  $: redeemUrl = window.location.origin + $router.routeURL({ name: 'redeem', params: { id: campaignId } })

  const saving = createLoadingStore()

  async function load () {
    await (loadingPromise = loading(async () => {
      ;[campaign, redeemConfig] = await Promise.all([
        apiCall('GET', uri`/api/campaigns/${campaignId}`),
        apiCall('GET', uri`/api/campaigns/${campaignId}/redeemConfig`)
      ])
    }))
  }

  async function save () {
    await saving(async () => {
      redeemConfig = await apiCall('PATCH', uri`/api/campaigns/${campaignId}/redeemConfig`, redeemConfig)
    })

    Toast.create({
      message: 'Redeem config saved',
      type: 'is-success'
    })

    $router.push({ name: 'campaignDetails', params: { id: campaignId } })
  }

  loadingPromise = load()
</script>

<svelte:head>
  <title>Redeem Settings for {campaign?.name ?? 'Campaign'} - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <h4 class="title is-4">
      Redeem Settings {campaign?.name ? `for "${campaign.name}"` : ''}
      <CampaignTags campaign={campaign} />
    </h4>

    {#if loadingPromise}
      {#await loadingPromise}
        <Loader borderless />
      {:then}
        <p class="mb-4">
          <strong>Redeem URL: <a href="{redeemUrl}" target="_blank" rel="noreferrer">{redeemUrl}</a></strong>
        </p>
        <form on:submit|preventDefault={save}>
          <fieldset disabled={$saving}>
            <Field label="Enabled">
              <Switch bind:checked={redeemConfig.enabled} />
            </Field>

            <Field label="GA4 Measurement ID" message="Pageviews and events are sent to this Google Analytics setup. Must be a Google Analytics V4 Measurement ID.">
              <Input bind:value={redeemConfig.ga4MeasurementId} />
            </Field>

            <Field label="Header Text" message="This text is shown at the top of the redeem page.">
              <Input bind:value={redeemConfig.headerText} required />
            </Field>

            <Field label="Main Text" message="This text is shown below the header text and a large icon.">
              <Input type="textarea" bind:value={redeemConfig.mainText} required />
            </Field>

            <Field label="Button Text" message="This text is shown on the redeem button (after wallet was connected).">
              <Input bind:value={redeemConfig.buttonText} required />
            </Field>

            <Field label="Success Text" message="This text is shown after the user successfully redeemed the NFT.">
              <Input type="textarea" bind:value={redeemConfig.successText} required />
            </Field>

            <Field label="Reward Code" message="A code (like a coupon code) displayed to the user after redeeming the NFT.">
              <Input bind:value={redeemConfig.rewardCode} />
            </Field>

            <Field label="Reward URL" message="A URL the user is directed to after redeeming the NFT.">
              <Input bind:value={redeemConfig.rewardUrl} />
            </Field>

            <Field label="Reward URL Text" message="The text of the button that directs the user to the reward URL.">
              <Input bind:value={redeemConfig.rewardUrlButtonText} />
            </Field>

            <div class="field is-grouped">
              <div class="control">
                <Button type="is-primary" nativeType="submit" loading={$saving}>Save</Button>
              </div>
              <div class="control">
                <Button on:click={() => $router.push({ name: 'campaignDetails', params: { id: campaignId } })} disabled={$saving}>Cancel</Button>
              </div>
            </div>
          </fieldset>
        </form>
      {:catch error}
        <ErrorDisplay title="Failed to load campaign" {error} />
      {/await}
    {/if}
  </div>
</div>
