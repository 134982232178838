<script>
  import { disconnectAllWallets, connectWallet, walletConnecting, activeWalletAddress } from '../stores/onboard'
  import { onMount } from 'svelte'
  import { Button, Icon } from 'svelma-fixed'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import Loader from '../components/Loader.svelte'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import { createLoadingStore } from '../stores/loading'
  import { authenticate } from '../stores/auth'
  import { GoogleAnalytics, ga } from '@beyonk/svelte-google-analytics'

  export let campaignId

  const submitting = createLoadingStore()

  onMount(async () => {
    await disconnectAllWallets()
  })

  async function connectWalletWithGa () {
    ga.addEvent('connectWalletInit', { campaignId })
    await connectWallet()
    if (!$activeWalletAddress) {
      ga.addEvent('connectWalletCancel', { campaignId })
    }
  }

  async function reconnectWallet () {
    ga.addEvent('switchWallet', { campaignId })

    await disconnectAllWallets()
    await connectWalletWithGa()
  }

  let redeemConfig
  let hasNfts
  let reward

  let loadingPromise

  async function load () {
    redeemConfig = await apiCall('GET', uri`/api/redeem/${campaignId}`)
  }

  loadingPromise = load()

  async function loadAddress (address) {
    hasNfts = undefined
    reward = undefined

    const result = await apiCall('GET', uri`/api/redeem/${campaignId}/${address}`)
    hasNfts = result.hasNfts

    ga.addEvent('connectWalletComplete', { campaignId, walletAddress: $activeWalletAddress, hasNfts })
  }

  async function redeem () {
    await submitting(async () => {
      ga.addEvent('redeemInit', { campaignId, walletAddress: $activeWalletAddress })

      const { authToken } = await authenticate(true) ?? {}
      if (!authToken) {
        ga.addEvent('redeemCancel', { campaignId, walletAddress: $activeWalletAddress })
        return
      }

      reward = await apiCall('POST', uri`/api/redeem/${campaignId}/${$activeWalletAddress}`, {}, authToken)
      ga.addEvent('redeemComplete', { campaignId, walletAddress: $activeWalletAddress })
    })
  }
</script>

<style lang="scss">
  .reward-code {
    font-size: 2rem;
    font-weight: bold;
    color: $danger;
    border: 4px dashed $grey;
    padding: 1rem;
    display: inline-block;
    margin: auto;
  }
</style>

<svelte:head>
	<title>Redeem - Tailor</title>
</svelte:head>

<div class="container">
  {#await loadingPromise}
    <Loader />
  {:then}
    {#if redeemConfig.ga4MeasurementId}
      <GoogleAnalytics
        properties={[redeemConfig.ga4MeasurementId]}
        configurations={{ [redeemConfig.ga4MeasurementId]: {} }}
      />
    {/if}

    <div class="box my-4 has-text-centered">
      <h1 class="title is-1 my-5">
        {redeemConfig.headerText}
      </h1>

      {#if reward}
        <p class="my-5"><i class="big-icon fa fa-thumbs-up"></i></p>

        <p class="is-size-4 pre-wrap">{redeemConfig.successText}</p>

        {#if reward.rewardCode}
          <div class="my-5 reward-code">{reward.rewardCode}</div>
        {/if}

        {#if reward.rewardUrl}
          <p class="my-5"><Button size="is-large" type="is-success" tag="a" href={reward.rewardUrl} on:click={() => ga.addEvent('rewardUrlClick', { campaignId, walletAddress: $activeWalletAddress })}>{redeemConfig.rewardUrlButtonText}</Button></p>
        {/if}
      {:else}
        <p class="my-5"><i class="big-icon fa fa-ticket-alt"></i></p>

        <p class="is-size-4 pre-wrap">{redeemConfig.mainText}</p>

        {#if $activeWalletAddress}
          <p class="my-5"><Icon icon="check-circle" /> Connected to wallet {$activeWalletAddress}</p>

          {#await loadAddress($activeWalletAddress)}
            <Loader borderless />
          {:then}
            {#if hasNfts}
              <p class="my-5"><Button size="is-large" type="is-success" on:click={() => redeem()} loading={$walletConnecting || $submitting}>{redeemConfig.buttonText}</Button></p>
            {:else}
              <p class="my-5"><strong class="has-text-danger"><Icon icon="frown" /> Unfortunately, you do not own any NFTs from this campaign!</strong></p>
            {/if}
          {:catch error}
            <ErrorDisplay title="Failed to load NFT info" {error} />
          {/await}
          <p><small><a href={undefined} on:click={() => reconnectWallet()}>Wrong wallet? Click here to connect a different wallet.</a></small></p>
        {:else}
          <p class="my-5"><Button size="is-large" on:click={() => connectWalletWithGa()} loading={$walletConnecting}>Connect Wallet</Button></p>
        {/if}
      {/if}
    </div>
  {:catch error}
    <ErrorDisplay title="Failed to load campaign" {error} />
  {/await}
</div>
