<script>
  import { Button, Icon, Notification, Toast, Dialog } from 'svelma-fixed'
  import ErrorDisplay from '../components/ErrorDisplay.svelte'
  import Loader from '../components/Loader.svelte'
  import { createLoadingStore, combineLoadingStores, loadingProps } from '../stores/loading'
  import { apiCall } from '../lib/api'
  import uri from 'uri-tag'
  import Tooltip from '../components/Tooltip.svelte'
  import { router } from '@spaceavocado/svelte-router'
  import { getAddressShortLabel, getTokenUrl, isDoneOrPending } from '../lib/utils'
  import { audienceTypes, chains } from '../lib/enums'
  import CampaignTags from '../components/CampaignTags.svelte'
  import BatchList from '../components/BatchList.svelte'
  import { RouterLink } from '../router'
  import { userInfo } from '../stores/auth'
  import fileDownload from 'js-file-download'

  export let id

  const loading = createLoadingStore()
  let loadingPromise

  let campaign

  async function load () {
    await (loadingPromise = loading(async () => {
      campaign = await apiCall('GET', uri`/api/campaigns/${id}`)
    }))
  }

  load()

  const archiving = createLoadingStore()
  const unarchiving = createLoadingStore()
  const deleting = createLoadingStore()
  const updatingName = createLoadingStore()
  const updatingContractName = createLoadingStore()

  const anyLoading = combineLoadingStores(loading, archiving, unarchiving, deleting, updatingName, updatingContractName)

  const downloadingImpressionsReport = createLoadingStore()

  async function archive () {
    await archiving(async () => {
      campaign = await apiCall('POST', uri`/api/campaigns/${id}/archive`)
      Toast.create({
        message: 'Campaign archived.',
        type: 'is-success'
      })
    })
  }

  async function unarchive () {
    await unarchiving(async () => {
      campaign = await apiCall('POST', uri`/api/campaigns/${id}/unarchive`)
      Toast.create({
        message: 'Campaign unarchived.',
        type: 'is-success'
      })
    })
  }

  async function deleteCampaign () {
    await deleting(async () => {
      if (await Dialog.confirm({
        message: 'Are you sure you want to delete this campaign?',
        type: 'is-danger'
      })) {
        await apiCall('DELETE', uri`/api/campaigns/${id}`)
        Toast.create({
          message: 'Campaign deleted.',
          type: 'is-success'
        })
        $router.push({ name: 'campaigns' })
      }
    })
  }

  function duplicate () {
    $router.push({ name: 'newCampaign', query: { duplicateCampaignId: id } })
  }

  function burn () {
    $router.push({ name: 'burnCampaign', params: { id } })
  }

  async function updateName (name) {
    await updatingName(async () => {
      let newName = await Dialog.prompt({
        message: 'Enter a new name for the campaign:',
        type: 'is-primary',
        icon: 'edit',
        prompt: campaign.name,
        showCancel: true,
        confirmText: 'Save'
      })

      if (newName == null) return
      newName = newName.trim()

      if (!newName) {
        Dialog.alert({
          message: 'Campaign name cannot be empty.',
          type: 'is-danger'
        })
        return
      }

      campaign = await apiCall('POST', uri`/api/campaigns/${id}/updateName`, { name: newName })

      Toast.create({
        message: 'Campaign name updated.',
        type: 'is-success'
      })
    })
  }

  async function updateContractName (name) {
    await updatingContractName(async () => {
      let newName = await Dialog.prompt({
        message: 'Enter a new name for the campaign contract:',
        type: 'is-primary',
        icon: 'edit',
        prompt: campaign.contractName,
        showCancel: true,
        confirmText: 'Save'
      })

      if (newName == null) return
      newName = newName.trim()

      if (!newName) {
        Dialog.alert({
          message: 'Contract name cannot be empty.',
          type: 'is-danger'
        })
        return
      }

      campaign = await apiCall('POST', uri`/api/campaigns/${id}/updateContractName`, { contractName: newName })

      Toast.create({
        message: 'Contract name updated.',
        type: 'is-success'
      })
    })
  }

  async function fetchImpressions () {
    const { count } = await apiCall('GET', uri`/api/campaigns/${id}/impressions`)
    return count
  }

  async function downloadImpressionsReport () {
    await downloadingImpressionsReport(async () => {
      const count = await fetchImpressions()
      if (count > 100000) {
        if (!await Dialog.confirm({
          message: `You are about to download a report with ${count} rows. This may take a while. Are you sure you want to continue?`,
          type: 'is-warning',
          showCancel: true,
          confirmText: 'Download'
        })) {
          return
        }
      }

      const { csv } = await apiCall('GET', uri`/api/campaigns/${id}/impressions`, { csv: true })
      fileDownload(csv, `impressions-${id}.csv`)
    })
  }

  async function fetchAudienceCount () {
    const { addressesCount } = await apiCall('POST', uri`/api/segmentationPreview`, { audience: campaign.audience })
    return addressesCount ?? 0
  }
</script>

<svelte:head>
	<title>{campaign?.name ?? 'Campaign Details'} - Tailor</title>
</svelte:head>

<div class="container">
  <div class="box my-4">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h4 class="title is-4">
            Campaign {campaign?.name ? `"${campaign.name}"` : ''}
            <CampaignTags {campaign} />
          </h4>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          {#if campaign}
            {#if !campaign.contractAddress}
              <Tooltip label="Delete"><Button type="is-white has-text-danger" on:click={deleteCampaign} {...loadingProps($anyLoading, $deleting)}><Icon icon="trash" /></Button></Tooltip>
            {:else if campaign.batches.filter(batch => isDoneOrPending(batch.minting) && !isDoneOrPending(batch.burning)).reduce((total, batch) => total + batch.count, 0) > 0}
              <Tooltip label="Burn"><Button type="is-white has-text-danger" on:click={burn} disabled={$anyLoading}><Icon icon="fire" /></Button></Tooltip>
            {/if}
            {#if $userInfo?.poweruser}
              {#if campaign?.archived}
                <Tooltip label="Unarchive"><Button type="is-white" on:click={unarchive} {...loadingProps($anyLoading, $unarchiving)}><Icon icon="reply" /></Button></Tooltip>
              {:else}
                <Tooltip label="Archive"><Button type="is-white" on:click={archive} {...loadingProps($anyLoading, $archiving)}><Icon icon="archive" /></Button></Tooltip>
              {/if}
            {/if}
            <Tooltip label="Duplicate"><Button type="is-white" on:click={duplicate} disabled={$anyLoading}><Icon icon="copy" /></Button></Tooltip>
          {/if}
          <Tooltip label="Reload"><Button type="is-white" on:click={load} disabled={$anyLoading}><Icon icon="sync" /></Button></Tooltip>
        </div>
      </div>
    </div>

    {#await loadingPromise}
      <Loader borderless />
    {:then}
      {#if campaign.owner !== $userInfo?.id}
        <Notification showClose={false} type="is-info"><Icon icon="info-circle" /> This campaign is owned by another user.</Notification>
      {/if}

      {#if campaign.multiBurning?.status === 'pending'}
        <Notification showClose={false}><Icon icon="info-circle" /> This campaign currently has a <RouterLink to={{ name: 'burnCampaign', params: { id } }}>burning operation</RouterLink> in progress.</Notification>
      {/if}

      <div class="columns">
        <div class="column is-half">
          <strong>Internal Campaign Name</strong>
          <p>
            {campaign.name}
            {#if $updatingName}
              <Icon icon="spinner" customClass="fa-pulse" />
            {:else}
              <Tooltip label="Edit"><a href={undefined} class="has-text-primary" on:click={() => updateName()} disabled={$anyLoading}><Icon icon="pencil" /></a></Tooltip>
            {/if}
          </p>
        </div>
        <div class="column is-half">
          <strong>Network</strong>
          <p>{chains.getName(campaign.network)}</p>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half">
          <strong>Public Contract Name</strong>
          <p>
            {campaign.contractName}
            {#if !campaign.contractAddress && !isDoneOrPending(campaign.deployment)}
              {#if $updatingContractName}
                <Icon icon="spinner" customClass="fa-pulse" />
              {:else}
                <Tooltip label="Edit"><a href={undefined} class="has-text-primary" on:click={() => updateContractName()} disabled={$anyLoading}><Icon icon="pencil" /></a></Tooltip>
              {/if}
            {/if}
          </p>
        </div>
        <div class="column is-half">
          <strong>Contract Address</strong>
          <p>
            {#if campaign.contractAddress}
              <Tooltip label="View on Etherscan"><a href={getTokenUrl(campaign.contractAddress, campaign.network)} target="_blank" rel="noreferrer"><Icon icon="external-link-alt" />{getAddressShortLabel(campaign.contractAddress)}</a></Tooltip>
            {:else}
              <span class="has-text-grey">(not yet deployed)</span>
            {/if}
          </p>
        </div>
      </div>

      {#if campaign.contractAddress}
        <div class="columns">
          <div class="column is-half">
            <strong>OpenSea Impressions</strong>
            <p>
              {#await fetchImpressions()}
                <Icon icon="spinner" customClass="fa-pulse" />
              {:then impressions}
                {impressions.toLocaleString()}
                {#if impressions > 0}
                  {#if $downloadingImpressionsReport}
                    <Icon icon="spinner" customClass="fa-pulse" />
                  {:else}
                    <Tooltip label="Download CSV Report"><a href={undefined} class="has-text-primary" on:click={() => downloadImpressionsReport()}><Icon icon="file-csv" /></a></Tooltip>
                  {/if}
                {/if}
              {:catch}
                <span class="has-text-grey">(not available)</span>
              {/await}
            </p>
          </div>
          <div class="column is-half">
            {#if $userInfo.poweruser}
              <strong>Redeem Page</strong>
              <p>
                <RouterLink to={{ name: 'redeemConfig', params: { id } }}><Icon icon="gear"/> Configure</RouterLink> -
                <a href={$router.routeURL({ name: 'redeem', params: { id } })} target="_blank" rel="noreferrer"><Icon icon="ticket-alt"/> View</a>
              </p>
            {/if}
          </div>
        </div>
      {/if}

      <hr />

      <h4 class="title is-4">
        <span class="mr-3">Audience</span>
        <Button size="is-small" type="is-primary" iconLeft="gear" on:click={() => $router.push({ name: 'campaignAudience', params: { id } })}>Manage</Button>
      </h4>

      {#if campaign.audience?.type}
        <div class="columns">
          <div class="column is-half">
            <strong>Type</strong>
            <p>{audienceTypes.getName(campaign.audience.type)}</p>
          </div>
          <div class="column is-half">
            <strong>Number of Addresses</strong>
            <p>
              {#if campaign.audience?.type === 'list'}
                {campaign.audience.addressesCount?.toLocaleString()}
              {:else if campaign.audience?.type === 'segmented'}
                {#await fetchAudienceCount()}
                  <Icon icon="spinner" customClass="fa-pulse" />
                {:then count}
                  {count.toLocaleString()}
                {:catch}
                  <span class="has-text-grey">Unknown</span>
                {/await}
              {:else}
                <span class="has-text-grey">Unknown</span>
              {/if}
            </p>
          </div>
        </div>
      {:else}
        <p><Icon icon="warning" /> No audience selected!</p>
      {/if}

      <hr />

      <h4 class="title is-4">
        <span class="mr-3">Batches</span>
        <Button size="is-small" type="is-primary" iconLeft="plus" on:click={() => $router.push({ name: 'newBatch', params: { id } })}>Mint New</Button>
      </h4>

      <BatchList {campaign} />
    {:catch error}
      <ErrorDisplay title="Failed to load campaign" {error} />
    {/await}
  </div>
</div>
