<script>
  import ModalDialog from '../ModalDialog.svelte'
  import Tooltip from '../../components/Tooltip.svelte'
  import { formatError, getAddressShortLabel, getTxUrl } from '../../lib/utils'
  import { Button, Icon } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let network
  export let tx

  function close () {
    dispatch('close')
  }
</script>

<ModalDialog large --min-width="400px" on:close title="Blockchain Transaction" closeable>
  <p>
    Transaction ID:
    <Tooltip position="is-bottom" label="View on Etherscan"><a href={getTxUrl(tx.hash, network)} target="_blank" rel="noreferrer"><Icon icon="external-link-alt" /> {getAddressShortLabel(tx.hash)}</a></Tooltip>
  </p>

  {#await tx.wait()}
    <p class="has-text-weight-bold">
      <Icon icon="spinner" customClass="fa-pulse" /> Waiting for transaction to be mined...
    </p>
  {:then receipt}
    {#if receipt.status === 1}
      <p class="has-text-success has-text-weight-bold">
        <Icon icon="check-circle" /> Transaction was successful.
      </p>
    {:else}
      <p class="has-text-danger has-text-weight-bold">
        <Icon icon="times-circle" /> Transaction failed! Please check the <a href={getTxUrl(tx.hash, network)} target="_blank" rel="noreferrer">block explorer</a> for details.
      </p>
    {/if}

    <Button class="is-fullwidth mt-3" type="is-primary" on:click={close}>Close</Button>
  {:catch error}
    <p class="has-text-danger has-text-weight-bold">
      <Icon icon="times-circle" /> A problem occured while waiting for the transaction to complete. ({formatError(error)})
    </p>

    <Button class="is-fullwidth mt-3" type="is-primary" on:click={close}>Close</Button>
  {/await}
</ModalDialog>
