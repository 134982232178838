import { writable, derived } from 'svelte/store'

export function createLoadingStore () {
  const loadingCount = writable(0)
  const store = async function (fn) {
    loadingCount.update($loadingCount => $loadingCount + 1)
    try {
      return await fn()
    } finally {
      loadingCount.update($loadingCount => $loadingCount - 1)
    }
  }
  Object.assign(store, derived(loadingCount, $loadingCount => $loadingCount > 0))
  return store // callable
}

export function combineLoadingStores (...stores) {
  return derived(stores, $stores => $stores.some($store => $store))
}

export function loadingProps ($loading, $thisLoading) {
  return {
    loading: $thisLoading,
    disabled: $loading && !$thisLoading
  }
}
