<script>
  import { createEventDispatcher } from 'svelte'
  import { apiCall } from '../lib/api'
  import AutoUpdate from './AutoUpdate.svelte'
  import ErrorDisplay from './ErrorDisplay.svelte'
  import Loader from './Loader.svelte'
  import { Button, Dialog, Notification, Toast } from 'svelma-fixed'
  import { formatCurrency, getTxUrl } from '../lib/utils'
  import dialogs from '../stores/dialogs'
  import BalanceKeeperDialog from './dialogs/BalanceKeeperDialog.svelte'
  import { createLoadingStore } from '../stores/loading'

  export let path
  export let network
  export let text

  export let action = null

  const dispatch = createEventDispatcher()

  let loadingPromise

  const cancelling = createLoadingStore()

  let balanceInfo
  let lastBalanceUpdate = 0

  export async function update (newState) {
    const prev = action
    action = newState ?? await apiCall('GET', path)

    if (prev && (prev?.status !== action?.status || prev?.hash !== action?.hash)) {
      dispatch('update', action)

      if (action?.status === 'success') {
        dispatch('success', action)
      } else if (action?.status === 'failed') {
        dispatch('failed', action)
      } else if (action?.status === 'dropped') {
        dispatch('dropped', action)
      }
    }

    if (action?.status === 'scheduled') {
      if (lastBalanceUpdate < Date.now() - 15000) {
        const balances = await apiCall('GET', '/api/balances', { withBalanceCheck: true, network })
        balanceInfo = balances[network]
        lastBalanceUpdate = Date.now()
      }
    } else {
      balanceInfo = undefined
    }
  }

  export async function setHash (hash, extra) {
    await update(hash === null ? undefined : await apiCall('PUT', path, { hash, extra }))
  }

  loadingPromise = update()

  async function openDepositDialog (network, amount) {
    await dialogs.open(BalanceKeeperDialog, { network, mode: 'deposit', initialAmount: amount })
    balanceInfo = undefined
    lastBalanceUpdate = 0
    loadingPromise = update()
  }

  async function cancelTask () {
    await cancelling(async () => {
      if (await Dialog.confirm({
        title: 'Cancel Scheduled Task',
        message: 'Are you sure you want to cancel this scheduled task? This will prevent the transaction from being submitted automatically.',
        type: 'is-danger',
        hasIcon: true
      })) {
        await apiCall('DELETE', path)
        balanceInfo = undefined
        lastBalanceUpdate = 0
        loadingPromise = update()

        await Toast.create({ message: 'The scheduled task has been deleted!', type: 'is-success' })
      }
    })
  }
</script>

<AutoUpdate interval={5000} on:tick={() => update()} />

<main>
  {#await loadingPromise}
    <Loader borderless />
  {:then}
    {#if balanceInfo?.depositRecommendation > 0}
      <Notification type="is-danger">
        <strong>Insufficient scheduled task funds!</strong>
        <p>
          Please deposit at least {balanceInfo.depositRecommendation.toFixed(6)} {balanceInfo.tokenName} ({formatCurrency(balanceInfo.depositRecommendation * balanceInfo.ethPrice, 'USD', 2)}) to allow for all scheduled tasks to be executed.
        </p>
        <Button type="is-danger" inverted outlined on:click={() => openDepositDialog(network, balanceInfo.depositRecommendation)}>Deposit Now</Button>
      </Notification>
    {/if}
    {#if !action?.status || action.status === 'dropped' || action.status === 'failed'}
      {#if action?.status === 'dropped'}
        <Notification type="is-warning">
          The <a href={getTxUrl(action.hash, network)} target="_blank" rel="noreferrer">previous transaction</a> was dropped from the network. You can submit a new transaction now.
        </Notification>
      {:else if action?.status === 'failed'}
        <Notification type="is-warning">
          The <a href={getTxUrl(action.hash, network)} target="_blank" rel="noreferrer">previous transaction</a> failed on the blockchain. You can submit a new transaction now.
        </Notification>
      {/if}
      <slot {setHash} />
    {:else if action.status === 'pending' || (action.status === 'success' && !$$slots.success)}
      <Loader borderless {text}>
        Waiting for <a href={getTxUrl(action.hash, network)} target="_blank" rel="noreferrer">transaction</a> to be mined...
        <slot name="pending" />
      </Loader>
    {:else if action.status === 'success'}
      <slot name="success" />
    {:else if action.status === 'scheduled' || action.status === 'processing'}
      <Loader borderless {text}>
        Your transaction is scheduled to be submitted at a lower gas price. Once that price is reached, your transaction will be submitted automatically.
        <p class="has-text-centered"><Button disabled={action.status === 'processing'} type="is-danger" inverted iconLeft="hand-paper" on:click={cancelTask} loading={$cancelling}>Cancel Task</Button></p>
        <slot name="pending" />
      </Loader>
    {/if}
  {:catch error}
    <ErrorDisplay title="Failed to update operation status" {error} />
  {/await}
</main>
