<script>
  import { onMount, onDestroy, tick } from 'svelte'
  import { router, RouterLink } from '../router'
  import NavbarWalletConnectButton from './NavbarWalletConnectButton.svelte'

  let navbarMenu
  let hamburgerIsActive = false

  onMount(() => {
    document.documentElement.classList.add('has-navbar-fixed-top')

    // This has to be done at the bubbling phase to avoid having the element removed already after it was clicked (so e.target.closest would not work)!
    navbarMenu.addEventListener('click', e => {
      if ((e.target.closest('a') || e.target.closest('button')) && !e.target.closest('.keep-menu-open')) {
        console.log(e.target, e.target.closest('a'), e.target.closest('button'), e.target.closest('.keep-menu-open'))
        tick().then(() => (hamburgerIsActive = false))
      }

      // Make sure the dropdowns won't just stay open after clicking an element on them
      // https://github.com/jgthms/bulma/issues/2514
      const dropdown = e.target.closest('.navbar-dropdown')
      if (dropdown) {
        const dropdownOuter = e.target.closest('.has-dropdown')
        dropdownOuter.classList.remove('is-hoverable')
        dropdown.style.display = 'none'
        setTimeout(() => {
          dropdownOuter.blur()
          dropdownOuter.classList.add('is-hoverable')
          dropdown.style.display = ''
        }, 150)
      }
    }, true)
  })
  onDestroy(() => {
    document.documentElement.classList.remove('has-navbar-fixed-top')
  })

  function toggleMenu () {
    hamburgerIsActive = !hamburgerIsActive
  }
</script>

<style lang="scss">
  // TODO: This is a workaround. We would normally *want* to have this scrollable on mobile/tablet, because otherwise things could become hidden
  // if there are too many navbar items, especially with subdropdown, if any. But this makes the coin selector break. We should find some other
  // solution.
  .navbar-menu {
    overflow: visible !important;
  }
</style>

<nav class="navbar is-fixed-top has-shadow" aria-label="main navigation">
  <div class="container">
    <div class="navbar-brand">
      {#if $router.currentRoute?.meta?.hideNav}
        <a class="navbar-item py-0" href="/">
          <img title="Tailor" src="/images/logo.svg" alt="Tailor" />
        </a>
      {:else}
        <RouterLink to={{ name: 'HOME' }} cls="navbar-item py-0">
          <img title="Tailor" src="/images/logo.svg" alt="Tailor" />
        </RouterLink>
      {/if}

      <a role="button" class="navbar-burger burger" class:is-active={hamburgerIsActive} aria-label="menu" aria-expanded="false" data-target="navbarMenu" href={undefined} on:click={toggleMenu}>
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>

    <div bind:this={navbarMenu} id="navbarMenu" class="navbar-menu" class:is-active={hamburgerIsActive}>
      <div class="navbar-start">
        {#if !$router.currentRoute?.meta?.hideNav}
          <RouterLink to={{ name: 'campaigns' }} cls="navbar-item">Campaigns</RouterLink>
          <RouterLink to={{ name: 'tasks' }} cls="navbar-item">Scheduled Tasks</RouterLink>
          <RouterLink to={{ name: 'settings' }} cls="navbar-item">Settings</RouterLink>
        {/if}
      </div>

      <div class="navbar-end">
        {#if !$router.currentRoute?.meta?.hideAccountCenter}
          <span class="navbar-item">
            <NavbarWalletConnectButton />
          </span>
        {/if}
      </div>
    </div>
  </div>
</nav>
