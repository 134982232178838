'use strict'

// https://spaceavocado.github.io/svelte-router/
import createRouter from '@spaceavocado/svelte-router'
import { get } from 'svelte/store'
import { userInfo } from './stores/auth'
import { appInfo } from './lib/appInfo'
import { onboard } from './stores/onboard'

export { default as RouterView } from '@spaceavocado/svelte-router/component/view'
export { default as RouterLink } from '@spaceavocado/svelte-router/component/link'

// View components
import View404 from './views/View404.svelte'
import ViewUnsubscribe from './views/ViewUnsubscribe.svelte'
import ViewRedeem from './views/ViewRedeem.svelte'
import ViewBetaLogin from './views/ViewBetaLogin.svelte'
import ViewTxTest from './views/ViewTxTest.svelte'
import ViewTxTest2 from './views/ViewTxTest2.svelte'
import ViewCampaigns from './views/ViewCampaigns.svelte'
import ViewNewCampaign from './views/ViewNewCampaign.svelte'
import ViewCampaignDetails from './views/ViewCampaignDetails.svelte'
import ViewCampaignAudience from './views/ViewCampaignAudience.svelte'
import ViewEditBatch from './views/ViewEditBatch.svelte'
import ViewBatchDetails from './views/ViewBatchDetails.svelte'
import ViewMintBatch from './views/ViewMintBatch.svelte'
import ViewBurnBatch from './views/ViewBurnBatch.svelte'
import ViewRedeemConfig from './views/ViewRedeemConfig.svelte'
import ViewTasks from './views/ViewTasks.svelte'
import ViewSettings from './views/ViewSettings.svelte'
import ViewConnect from './views/ViewConnect.svelte'

import { Toast } from 'svelma-fixed'

export const router = createRouter({
  activeClass: 'is-active',
  routes: [
    {
      path: '/',
      name: 'HOME',
      redirect: { name: 'dashboard' }
    },
    {
      path: '/unsubscribe/:id',
      name: 'unsubscribe',
      component: ViewUnsubscribe,
      meta: { hideNav: true, hideAccountCenter: true },
      props: route => ({ userId: route.params.id })
    },
    {
      path: '/redeem/:id',
      name: 'redeem',
      component: ViewRedeem,
      meta: { hideNav: true, hideAccountCenter: true },
      props: route => ({ campaignId: route.params.id })
    },
    {
      path: '/beta-login',
      name: 'betaLogin',
      component: ViewBetaLogin,
      meta: { hideNav: true, hideAccountCenter: true }
    },
    {
      path: '/tx-test',
      name: 'txTest',
      component: ViewTxTest
    },
    {
      path: '/tx-test-2',
      name: 'txTest2',
      component: ViewTxTest2
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      redirect: { name: 'campaigns' },
      meta: { ifConnected: true }
    },
    {
      path: '/campaigns',
      name: 'campaigns',
      component: ViewCampaigns,
      meta: { ifConnected: true }
    },
    {
      path: '/campaigns/new',
      name: 'newCampaign',
      component: ViewNewCampaign,
      meta: { ifConnected: true },
      props: route => ({ network: route.query.network, duplicateCampaignId: route.query.duplicateCampaignId })
    },
    {
      path: '/campaigns/:id',
      name: 'campaignDetails',
      component: ViewCampaignDetails,
      meta: { ifConnected: true },
      props: route => ({ id: route.params.id })
    },
    {
      path: '/campaigns/:id/audience',
      name: 'campaignAudience',
      component: ViewCampaignAudience,
      meta: { ifConnected: true },
      props: route => ({ id: route.params.id, continueToMint: route.query.continueToMint === '1' })
    },
    {
      path: '/campaigns/:id/batches/new',
      name: 'newBatch',
      component: ViewEditBatch,
      meta: { ifConnected: true },
      props: route => ({ campaignId: route.params.id, duplicateBatchId: route.query.duplicateBatchId })
    },
    {
      path: '/campaigns/:campaignId/batches/:batchId',
      name: 'batchDetails',
      component: ViewBatchDetails,
      meta: { ifConnected: true },
      props: route => ({ campaignId: route.params.campaignId, batchId: route.params.batchId })
    },
    {
      path: '/campaigns/:campaignId/batches/:batchId/edit',
      name: 'editBatch',
      component: ViewEditBatch,
      meta: { ifConnected: true },
      props: route => ({ campaignId: route.params.campaignId, batchId: route.params.batchId })
    },
    {
      path: '/campaigns/:campaignId/batches/:batchId/mint',
      name: 'mintBatch',
      component: ViewMintBatch,
      meta: { ifConnected: true },
      props: route => ({ campaignId: route.params.campaignId, batchId: route.params.batchId })
    },
    {
      path: '/campaigns/:campaignId/batches/:batchId/burn',
      name: 'burnBatch',
      component: ViewBurnBatch,
      meta: { ifConnected: true },
      props: route => ({ campaignId: route.params.campaignId, batchId: route.params.batchId })
    },
    {
      path: '/campaigns/:id/burn',
      name: 'burnCampaign',
      component: ViewBurnBatch,
      meta: { ifConnected: true },
      props: route => ({ campaignId: route.params.id })
    },
    {
      path: '/campaigns/:id/redeem-config',
      name: 'redeemConfig',
      component: ViewRedeemConfig,
      meta: { ifConnected: true },
      props: route => ({ campaignId: route.params.id })
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: ViewTasks,
      meta: { ifConnected: true }
    },
    {
      path: '/settings',
      name: 'settings',
      component: ViewSettings,
      meta: { ifConnected: true }
    },
    {
      path: '/connect',
      name: 'connect',
      component: ViewConnect,
      meta: { ifConnected: false }
    },
    {
      path: '*',
      component: View404
    }
  ]
})

export function checkAuth (route = get(router).currentRoute, next = route => { if (route) get(router).push(route) }) {
  if (appInfo.incorrectBetaPassword && route && !route?.meta?.hideNav) {
    console.log('Redirecting to beta login page')
    return next({ name: 'betaLogin' })
  }

  if (get(userInfo)) {
    if (route?.meta?.ifConnected === false) {
      console.log('Redirecting to dashboard page')
      return next({ name: 'dashboard' })
    }
  } else {
    if (route?.meta?.ifConnected === true) {
      console.log('Redirecting to connect page')
      return next({ name: 'connect' })
    }
  }
  next()
}

get(router).navigationGuard((from, to, next) => {
  console.log('Navigation attempt:', from, to)
  if (appInfo.initialized) checkAuth(to, next)
})

userInfo.subscribe(() => {
  if (appInfo.initialized) checkAuth()
})

get(router).onError(error => {
  Toast.create({ message: error, type: 'is-danger' })
  console.error('Router error', error)
})

get(router).onNavigationChanged((from, to) => {
  const hideAccountCenter = !!to?.meta?.hideAccountCenter
  if (onboard.state.get().accountCenter.enabled !== !hideAccountCenter) {
    onboard.state.actions.updateAccountCenter({
      enabled: !hideAccountCenter
    })
  }
})

export let shouldFixRouter = true

// This can be used to update props and route without reinstantiating component
export function softNavigate (route, operation = 'push') {
  try {
    shouldFixRouter = false
    if (route) get(router)[operation](route)
  } finally {
    setTimeout(() => {
      shouldFixRouter = true
    }, 0)
  }
}
